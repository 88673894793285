import * as React from "react";
import homeStyles from "../../styles/home/Home.module.scss";
import pageStyles from "../../styles/shared/Page.module.scss";
import classNames from "classnames";
import { isMobileOnly } from "react-device-detect";
import { SECRET_SANTA_SITES } from "../data/ScottSecretSantaData";
import { ItemContainer } from "./ItemContainer";
import { difference } from "lodash";


export const Home = React.memo(function HomeInternal() {

  const headlineSite = SECRET_SANTA_SITES[0];
  const priorSites = difference(SECRET_SANTA_SITES, [headlineSite])

  return (
    <div
      className={classNames(pageStyles.page, homeStyles.homeApp, {
        [homeStyles.mobile]: isMobileOnly
      })}
    >
      <div className={homeStyles.pageTitle}>Scott Secret Santa</div>
      {isMobileOnly ? (
        <div className={homeStyles.middleContainer}>
          {SECRET_SANTA_SITES.map(site => <ItemContainer key={site.title} linkTo={site.relativeUrl} backgroundImage={site.backgroundImg} metadata={site.year} />)}
        </div>
      ) : <React.Fragment>
        <div className={homeStyles.middleContainer}>
          <ItemContainer linkTo={headlineSite.relativeUrl} backgroundImage={headlineSite.backgroundImg} metadata={headlineSite.year} />
        </div>
        <div className={homeStyles.bottomContainer}>
          {priorSites.map(site => <ItemContainer key={site.title} linkTo={site.relativeUrl} backgroundImage={site.backgroundImg} metadata={site.year} />)}
        </div>
      </React.Fragment>}
    </div>
  );
});

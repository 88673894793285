import { AloneState, INITIAL_ALONE_STATE } from "../alone/state/AloneState";
import {
  BuzzFeedState,
  INITIAL_BUZZFEED_STATE,
} from "../buzzfeed/state/BuzzFeedState";
import {
  INITIAL_PELOTON_STATE,
  PelotonState,
} from "../peloton/redux/PelotonState";
import {
  INITIAL_SCOTTFLIX_STATE,
  ScottflixState,
} from "../scottflix/redux/ScottflixState";

export interface GlobalState {
  aloneState: AloneState;
  buzzFeedState: BuzzFeedState;
  pelotonState: PelotonState;
  scottflixState: ScottflixState;
}

export function createInitialGlobalState(): GlobalState {
  return {
    aloneState: INITIAL_ALONE_STATE,
    buzzFeedState: INITIAL_BUZZFEED_STATE,
    pelotonState: INITIAL_PELOTON_STATE,
    scottflixState: INITIAL_SCOTTFLIX_STATE,
  };
}

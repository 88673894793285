import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/alone/ShowHeaderBar.module.scss";
import { CONTESTANTS } from "../data/AloneData";
import { AloneActions } from "../state/AloneActions";
import { getAloneSelectedContestant } from "../state/AloneSelectors";

export const ShowHeaderBar = React.memo(function ShowHeaderBarInternal() {
  const dispatch = useDispatch();
  const selectedContestant = useSelector(getAloneSelectedContestant);

  const goToAloneShowHome = React.useCallback(() => {
    dispatch(AloneActions.selectContestant(undefined));
  }, [dispatch]);

  const goToNextContestant = React.useCallback(() => {
    const currentIndex =
      selectedContestant != null
        ? CONTESTANTS.findIndex((c) => c.key === selectedContestant)
        : undefined;
    if (currentIndex != null) {
      if (currentIndex < CONTESTANTS.length - 1) {
        const nextContestant = CONTESTANTS[currentIndex + 1].key;
        dispatch(AloneActions.selectContestant(nextContestant));
      } else {
        const nextContestant = CONTESTANTS[0].key;
        dispatch(AloneActions.selectContestant(nextContestant));
      }
    }
  }, [selectedContestant, dispatch]);

  const goToPreviousContestant = React.useCallback(() => {
    const currentIndex =
      selectedContestant != null
        ? CONTESTANTS.findIndex((c) => c.key === selectedContestant)
        : undefined;
    if (currentIndex != null) {
      if (currentIndex > 0) {
        const nextContestant = CONTESTANTS[currentIndex - 1].key;
        dispatch(AloneActions.selectContestant(nextContestant));
      } else {
        const nextContestant = CONTESTANTS[CONTESTANTS.length - 1].key;
        dispatch(AloneActions.selectContestant(nextContestant));
      }
    }
  }, [selectedContestant, dispatch]);

  return (
    <div className={styles.header}>
      <div className={styles.leftContainer}>
        {selectedContestant != null && (
          <div className={styles.navItem} onClick={goToPreviousContestant}>
            <Icon className={styles.navIcon} icon={IconNames.CHEVRON_LEFT} />
            PREVIOUS
          </div>
        )}
      </div>
      <div className={styles.mainContainer}>
        <div
          className={styles.showImage}
          style={{ backgroundImage: `url(img/alone/alone.png)` }}
          onClick={goToAloneShowHome}
        />
      </div>
      <div className={styles.rightContainer}>
        {selectedContestant != null && (
          <div className={styles.navItem} onClick={goToNextContestant}>
            NEXT
            <Icon className={styles.navIcon} icon={IconNames.CHEVRON_RIGHT} />
          </div>
        )}
      </div>
    </div>
  );
});

import { createSelector } from "reselect";
import { GlobalState } from "../../state/globalState";

export const getAloneSelectedTab = createSelector(
  (state: GlobalState) => state.aloneState,
  (aloneState) => {
    return aloneState.selectedTab;
  }
);

export const getAloneSelectedContestant = createSelector(
  (state: GlobalState) => state.aloneState,
  (aloneState) => {
    return aloneState.selectedContestant;
  }
);

import { defineAction } from "redoodle";
import { AloneNavTab, Contestants } from "../data/AloneData";

export const PREFIX = "@scott-secret-santa//alone";

export const selectTab = defineAction(`${PREFIX}//selectTab`)<AloneNavTab>();

export const selectContestant = defineAction(`${PREFIX}//selectContestant`)<
  keyof typeof Contestants | undefined
>();

export const AloneActions = {
  selectTab,
  selectContestant,
};

import * as React from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import styles from "../../styles/buzzfeed/ContentPreviewBody.module.scss";
import { BuzzFeedNavTab, QUIZ_NAME } from "../data/BuzzFeedData";
import { BuzzFeedActions } from "../state/BuzzFeedActions";
import { ContentPreview } from "./ContentPreview";
import { PostPreview } from "./PostPreview";

export const ContentPreviewBody = React.memo(
  function ContentPreviewBodyInternal() {
    const dispatch = useDispatch();

    const selectFeaturedContent = useCallback(() => {
      dispatch(BuzzFeedActions.selectTab(BuzzFeedNavTab.QUIZ));
    }, [dispatch]);

    return (
      <div className={styles.showPreviewBody}>
        <div className={styles.featuredContentContainer}>
          <div className={styles.headline}>
            <ContentPreview
              title={QUIZ_NAME}
              subtitle="Take the Quiz to Find Out!"
              selectItem={selectFeaturedContent}
              image="/img/buzzfeed/ScottFamilyPhoto.jpg"
            />
          </div>
          <div className={styles.sidebar}>
            <div className={styles.topPostsContainer}>
              <div className={styles.topPostsTitle}>Top Posts</div>
              <PostPreview
                title="Underage drinking legalized in Texas, infants flock to bars"
                image="/img/buzzfeed/top-posts/infants.jpg"
              />
              <PostPreview
                title="Super athletic Rameson family unsurprisingly dominates athletic event"
                image="/img/buzzfeed/top-posts/ramesonfam.jpg"
              />
              <PostPreview
                title="Critics warn new RSV vaccine linked to crying in infants"
                image="/img/buzzfeed/top-posts/rsv.jpg"
              />
              <PostPreview
                title="Twins separated at birth reunite then realize they aren’t actually twins"
                image="/img/buzzfeed/top-posts/twins.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

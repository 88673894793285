import classNames from "classnames";
import * as React from "react";
import pelotonHomeStyles from "../../styles/peloton/PelotonHome.module.scss";
import { Instructors, INSTRUCTORS, InstructorType } from "../data/PelotonData";

declare namespace InstructorList {
  export interface Props {
    classType: InstructorType;
    onClick: (instructor: keyof typeof Instructors) => void;
  }
}



// eslint-disable-next-line no-redeclare
export default class InstructorList extends React.Component<InstructorList.Props> {
  render() {
    const { classType, onClick} = this.props;
    const filteredInstructors = classType === InstructorType.ALL ? INSTRUCTORS : INSTRUCTORS.filter(i => i.type.includes(classType));

    return (
        <div className={pelotonHomeStyles.instructors}>
        {filteredInstructors.map(instructor => (
          <div key={instructor.key + "_tile"} className={pelotonHomeStyles.instructorTile} onClick={() => onClick(instructor.key)}>
            <div className={classNames(pelotonHomeStyles.tilePic, {[pelotonHomeStyles.shiftLeftNutmeg]: instructor.key === "NUTMEG", [pelotonHomeStyles.shiftLeftAnna]: instructor.key === "ANNA"} )}>
              {instructor.tileImgUrl != null && <img src={instructor.tileImgUrl} alt=""/>}
            </div>
            <div className={pelotonHomeStyles.tileMetadata}> 
              <div className={pelotonHomeStyles.tileName}>{instructor.name}</div>
              <div className={pelotonHomeStyles.tileType}>{instructor.type}</div>
            </div>
            {instructor.key === "TOTS" && <div className={pelotonHomeStyles.guestTag}>Guest</div>}
          </div>
        ))}
      </div>
    );
  }
}

import { Colors, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { useDispatch } from "react-redux";
import styles from "../../styles/buzzfeed/BuzzFeedHome.module.scss";
import { BuzzFeedNavTab } from "../data/BuzzFeedData";
import { BuzzFeedActions } from "../state/BuzzFeedActions";

export const BuzzFeedHeaderBar = React.memo(
  function BuzzFeedHeaderBarInternal() {
    const dispatch = useDispatch();

    return (
      <div className={styles.header}>
        <div className={styles.headerMenu}>
          <Icon icon={IconNames.MENU} color={Colors.DARK_GRAY2} />
        </div>
        <div className={styles.headerLogo}>
          <img
            className={styles.headerLogoImage}
            src="/img/buzzfeed/buzzfeed_logo.png"
            alt=""
            onClick={() =>
              dispatch(BuzzFeedActions.selectTab(BuzzFeedNavTab.HOME))
            }
          />
        </div>
        <div className={styles.navContainer}>
          <div
            className={styles.navItem}
            onClick={() =>
              dispatch(BuzzFeedActions.selectTab(BuzzFeedNavTab.HOME))
            }
          >
            Quizzes
          </div>
          <div
            className={styles.navItem}
            onClick={() =>
              dispatch(BuzzFeedActions.selectTab(BuzzFeedNavTab.RULES))
            }
          >
            Rules
          </div>
        </div>
      </div>
    );
  }
);

import * as React from "react";
import styles from "../../styles/buzzfeed/ContentPreviewBody.module.scss";

interface ContentPreviewProps {
  title: string;
  subtitle: string;
  image: string;
  selectItem?: () => void;
}

export const ContentPreview = React.memo(function ContentPreviewInternal({
  title,
  subtitle,
  image,
  selectItem,
}: ContentPreviewProps) {
  return (
    <div className={styles.previewContainer} onClick={selectItem}>
      <div className={styles.contentPreviewImageContainer}>
        <div
          className={styles.showImage}
          style={{ backgroundImage: "url(" + image + ")" }}
        />
      </div>
      <div className={styles.previewMetadata}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
    </div>
  );
});

import * as React from "react";
import scottifyStyles from "../../styles/scottify/Scottify.module.scss";
import styles from "../../styles/scottify/ScottifyBody.module.scss";
import { Classes, Colors, Icon } from "@blueprintjs/core";
import { IconName, IconNames } from "@blueprintjs/icons";
import TrackRow from "./TrackRow";
import AlbumCover from "./AlbumCover";
import { CATEGORIES, Playlist } from "../data/ScottifyData";
import classNames from "classnames";
import { Link } from "react-router-dom";
import PlaylistAlbumCover from "./PlaylistAlbumCover";
import ScottifyRules from "./ScottifyRules";


declare namespace ScottifyBody {
  export interface Props {
    playlist: Playlist;
    selectPlaylist: (playlist: Playlist) => void;
    nextPlaylist: () => void;
    previousPlaylist: () => void;
  }

  export interface State {
    selectedTab: "HOME" | "SEARCH";
    mode: "PLAYLIST" | "ALBUMS";
  }
}

// eslint-disable-next-line no-redeclare
export default class ScottifyBody extends React.Component<
ScottifyBody.Props,
ScottifyBody.State
> {
  state: ScottifyBody.State = {
    selectedTab: "HOME",
    mode: "ALBUMS",
  };

  render() {
    const { selectedTab } = this.state;
    return (
      <div className={styles.scottifyBodyNew}>
        <div className={styles.leftSidePanel}>{this.renderSidePanel()}</div>
        <div className={styles.mainPanel}>{selectedTab === "SEARCH"? this.renderMainPanelRules() : this.renderMainPanel()}</div>
      </div>
    );
  }

  private renderMainPanelRules = () => {
    return (
      <div className={styles.mainPanel}>
        <div className={styles.mainPanelHeader}>
          <div className={styles.headerLeft} />
          <div className={styles.headerRight}>
            <div className={styles.premiumTag}>Premium</div>
          </div>
        </div>
        <div className={styles.mainPanelContent}><ScottifyRules /></div>
      </div>
    )
  }

  private renderMainPanel = () => {
    const { playlist } = this.props;
    const { mode } = this.state;
    return (
      <div className={styles.mainPanel}>
        <div className={styles.mainPanelHeader} style={{backgroundColor: mode === "PLAYLIST" ? playlist.color || " rgb(162, 188, 142)" : ""}}>
          <div className={styles.headerLeft}>
            <Icon className={classNames(styles.headerNavButton, {[styles.disabled]: mode === "ALBUMS", [styles.bordered]: mode === "PLAYLIST"})} icon={IconNames.CHEVRON_LEFT} iconSize={20} onClick={() => this.handleChangeMode("ALBUMS")}/>
            <Icon className={classNames(styles.headerNavButton, styles.disabled, {[styles.bordered]: mode === "PLAYLIST"})} icon={IconNames.CHEVRON_RIGHT} iconSize={20} />
          </div>
          <div className={styles.headerRight}>
            <div className={styles.premiumTag}>Premium</div>
          </div>
        </div>
        <div className={styles.mainPanelContent}>{mode === "ALBUMS" ? this.renderAlbumsList() : this.renderPlaylistView()}</div>
      </div>
    )
  }

  private handleClickPlay = () => {
    window.open(this.props.playlist.link, "_blank");
  };

  private renderPlaylistView = () => {
    const { playlist } = this.props;

    return (
      <div className={styles.playlistViewContainer}>
        <div className={styles.playlistViewBackground} style={{backgroundColor: playlist.color || " rgb(162, 188, 142)"}} />
      <div className={styles.playlistView}>
        <div className={styles.playlistViewHeader}>
          <div className={styles.albumPreviewContainer}>
            <PlaylistAlbumCover playlist={playlist} />
          </div>
          <div className={styles.albumMetadataContainer}>
          <div className={styles.metadataHeader}>{"PLAYLIST"}</div>
            <div className={styles.metadataTitle}>{playlist.title}</div>
            <div className={styles.metadataDescription}>{playlist.description}</div>
          </div>
        </div>
        <div className={styles.playlistViewContent}>
          <div className={styles.controlContainer}>
            <Icon className={styles.controlPlay} icon={IconNames.PLAY} color={Colors.WHITE} iconSize={32} onClick={this.handleClickPlay} />
            <Icon className={styles.controlMore} icon={IconNames.MORE} color={Colors.GRAY3} iconSize={26}/>
          </div>
          <div>
          <div className={styles.tracksTable}>
          <div className={classNames(styles.tracksTableHeader, styles.tableRow)}>
                  <div className={classNames(styles.tableCol, styles.colPlay)}>
                    <Icon className="play-icon" icon={IconNames.PLAY} />
                  </div>
                  <div className={classNames(styles.tableCol, styles.colTrack)}>Track</div>
                  <div className={classNames(styles.tableCol, styles.colTitle)}>Title</div>
                  <div className={classNames(styles.tableCol, styles.colArtist)}>Artist</div>
                  <div className={classNames(styles.tableCol, styles.colDuration)} />
                </div>
          {playlist.trackList.map((track, index) => (
              <TrackRow
                key={"track_" + index}
                track={track}
                trackNumber={index+1}
              />
            ))}
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }

  private renderAlbumsList = () => {
    return (
      <div className={styles.albumsView}>
        {CATEGORIES.map(category => {
          return (
            <div className={styles.categoryContainer} key={category.title}>
              <div className={styles.categoryTitle}>{category.title}</div>
              <div className={styles.categoryDescription}>{category.description}</div>
              <div className={styles.playlistsContainer}>
                {category.playlists.map((p, index) => (
                  <div className={styles.albumCoverContainer}>
                    <AlbumCover
                      playlist={p}
                      isSelected={false}
                      onClick={this.handleSelectPlaylist}
                      key={"playlist_" + index}
                    />
                    <div className={styles.albumPlay}><Icon className={classNames(styles.albumPlayIcon, Classes.ELEVATION_3)} icon={IconNames.PLAY} iconSize={20}/></div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  private handleChangeMode = (newMode: "PLAYLIST" | "ALBUMS") => {
    this.setState({ mode: newMode });
  };

  private handleSelectPlaylist = (playlist: Playlist) => {
    if (playlist.fake) {
      window.open(
        playlist.link,
        "_blank"
      );
      return;
    }

    this.props.selectPlaylist(playlist);
    this.handleChangeMode("PLAYLIST");
  };

  private renderLogo = () => {
    return (
      <div className={styles.logoContainer}>
      <Link className={scottifyStyles.webNavBarImage} to="/scottify">
        <img
          className={styles.scottifyLogo}
          src="/img/scottify/ScottifyLogo_White.png"
          alt=""
        />
      </Link>
      </div>
    )
  }

  private renderButton(label: string, icon: IconName, selected: boolean, onClick?: () => void) {
    return (
      <div className={classNames(styles.sidePanelButton, {[styles.selected]: selected})} onClick={onClick}>
        <Icon className={styles.sidePanelButtonIcon} icon={icon} iconSize={18}/>
        {label}
      </div>
    )
  }

  private handleChangeTab = (newTab: "HOME" | "SEARCH") => {
    this.setState({ selectedTab: newTab });
  };

  private renderSidePanel = () => {
    const {selectedTab } =this.state;
    return (
    <React.Fragment>
      {this.renderLogo()}
      <div className={styles.sidePanelButtons}>
      {this.renderButton("Home", IconNames.HOME, selectedTab === "HOME", () => this.handleChangeTab("HOME"))}
      {this.renderButton("Rules", IconNames.SEARCH, selectedTab === "SEARCH", () => this.handleChangeTab("SEARCH"))}
      {/* {this.renderButton("Your Library", IconNames.BOOK, selectedTab === "LIBRARY")} */}
      </div>
    </React.Fragment>
    )
  }
}

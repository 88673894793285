import { AloneNavTab, Contestants } from "../data/AloneData";

export interface AloneState {
  selectedTab: AloneNavTab;
  selectedContestant: keyof typeof Contestants | undefined;
}

export const INITIAL_ALONE_STATE: AloneState = {
  selectedTab: AloneNavTab.HOME,
  selectedContestant: undefined,
};

export const getInitialAloneState = (): AloneState => INITIAL_ALONE_STATE;

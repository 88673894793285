import { Artist, Artists, ARTISTS } from "./Artists";

export interface Category {
  title: string;
  description?: string;
  playlists: Playlist[];
}

export interface Playlist {
  title: string;
  description: string;
  image: string;
  link?: string;
  trackList: Track[];
  color?: string;
  fake?: boolean;
}

export interface Track {
  title: string;
  artist: Artist;
  link: string;
}

export const THE_ONES_THAT_GOT_AWAY: Playlist = {
  title: "The Ones That Got Away",
  description: "Welcome (finally) to Rival",
  image: "playlist_theonesthatgotaway.jpg",
  trackList: [
    {
      title: "Since U Been Gone",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/3xrn9i8zhNZsTtcoWgQEAd"
    },
    {
      title: "Just A Dream",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/3ZdJffjzJWFimSQyxgGIxN"
    },
    {
      title: "Wait For You",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/0U969xYNlAyfzi8P1TaO7u"
    },
    {
      title: "Coming Home",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/6Iocm6VMu6bVNYwiDoyQFW"
    }
  ]
};

export const FRESH_FINDS: Playlist = {
  title: "Fresh Finds",
  description: "Fresh music every year, from local gigs to the big stage",
  image: "playlist_freshfinds.jpg",
  trackList: [
    {
      title: "Welcome To My Life",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/714Lw0m2SmCEhKSPw0Dn8J"
    },
    {
      title: "We Are Young",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/7a86XRg84qjasly9f6bPSD?si=1l19bQdhT6OCoPmetyeHOw"
    },
    {
      title: "Breaking Free",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/5zQ1Wq6HNYjGZWkVv5P8Eg"
    }
  ]
};

export const KIDS_BOP_19: Playlist = {
  title: "Kidz Bop 19",
  description: "Family-friendly sing-a-longs for the developing minds",
  image: "playlist_kidzbop19.jpg",
  trackList: [
    {
      title: "I Want Candy",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/7sruBwHu4S0DIo8RXKxsAY?si=bNkF1c5sSs6DILOUTuBkiw"
    },
    {
      title: "Young Dumb & Broke",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/5Z3GHaZ6ec9bsiI5BenrbY"
    },
    {
      title: "Sixteen Going on Seventeen",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/34IIktVCLfkbysTSYcKo3k"
    }
  ]
};

export const LADIES_EIGHTIES: Playlist = {
  title: "Ladies 80s",
  description: "Groovy beats for a memorable night on the dancefloor",
  image: "playlist_ladieseighties.jpg",
  trackList: [
    {
      title: "Lady Marmalade",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/7GQqj9jRtDkMp8zByehXQI?si=jQXn74V2RJirNKOWCI73XQ"
    },
    {
      title: "Walking On Sunshine",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/05wIrZSwuaVWhcv5FfqeH0?si=IkU-MwsLTVyYrZKCnTfV3Q"
    },
    {
      title: "Pump Up The Jam",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/0UAEHlFR79k9CJvknSGUNf?si=haBZXVj5RN-SrGQn4VFFMA"
    },
    {
      title: "What a Feeling",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/0aAR5HogGoT68EWFbyRFqx?si=6lPWISQQSWayIzPqgHz5dA"
    },
    {
      title: "Girls Just Wanna Have Fun",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/4y1LsJpmMti1PfRQV9AWWe?si=bPllCWuWR7i6Dbs5Svpm5g"
    },
    {
      title: "Sweet Dreams (Are Made of This)",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/1TfqLAPs4K3s2rJMoCokcS?si=u1dmusvZQtyGWKldXQccmw"
    }
  ]
};

export const THIS_IS_PITBULL: Playlist = {
  title: "This Is: Pitbull",
  description: "Mr. Worldwide right in Steel City",
  image: "playlist_thisispitbull.jpg",
  trackList: [
    {
      title: "Wild Wild Love",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/2J4Alm5zUad4hB5bY2HhtV?context=spotify%3Aplaylist%3A37i9dQZF1DXcfXDjovoEpj&si=YLmjqhgzTUCwPzhcJ77kMA"
    },
    {
      title: "I Know You want Me (Calle Ocho)",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/5RzFJd6W40SDTyZkX6xx45?context=spotify%3Aplaylist%3A37i9dQZF1DXcfXDjovoEpj&si=BOOIcw2OQVuXckvYCPc8hw"
    },
    {
      title: "International Love",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/62zFEHfAYl5kdHYOivj4BC?si=JxLVxcnJTfOL8WJt2sZx2g"
    },
    {
      title: "Hotel Room Service",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/6Rb0ptOEjBjPPQUlQtQGbL?context=spotify%3Aplaylist%3A37i9dQZF1DXcfXDjovoEpj&si=ME1ihtbKRhagr_0Ja8DhNA"
    },
    {
      title: "Messin' Around",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/2moTY9zKAZkRsODU4TUXY6?si=IibVa52VSaW15qC9qjhQzA"
    }
  ]
};

export const THIS_IS_PAVAROTTI: Playlist = {
  title: "This Is: Pavarotti",
  description: "An unforgettable voice always chasing the spotlight",
  image: "playlist_thisispavarotti.jpg",
  trackList: [
    {
      title: "Do You Want to Build a Snowman?",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/2yi7HZrBOC4bMUSTcs4VK6"
    }
  ]
};

export const EMO_FOREVER: Playlist = {
  title: "Emo Forever",
  description: "Emo anthems for the middle school spirits",
  image: "playlist_emoforever.jpg",
  trackList: [
    {
      title: "Dirty Little Secret",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/5lDriBxJd22IhOH9zTcFrV"
    },
    {
      title: "Sk8tr Boi",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/00Mb3DuaIH1kjrwOku9CGU"
    }
  ]
};

export const MORNING_COMMUTE: Playlist = {
  title: "Morning Commute",
  description: "Brought to you by solo artists always on the road",
  image: "playlist_morningcommute.jpg",
  trackList: [
    {
      title: "Leaving, On a Jet Plane",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/3D8dwH690MXQRhtIZTSS9c?si=NE5zseuhRjKsT76nWMSzzw"
    },
    {
      title: "Dancing On My Own",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/7g13jf3zqlP5S68Voo5v9m?si=brZE8fIfRAmMlpi5TMwdSg"
    },
    {
      title: "Lonely",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/6ls5ulRydoPE7oWGPGBqFA?si=iiBvZZrFSru3SRxZM8KTLA"
    },
    {
      title: "On the Road Again",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/1OmKo4t4Bh95xQI6WGiUR3?si=OXex3riCQViITDjCT8uTKA"
    },
    {
      title: "Life is a Highway",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/2Fs18NaCDuluPG1DHGw1XG?si=IFSETXHPSViN07utivGQZg"
    },
    {
      title: "Ridin' Solo",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/6BaxqcoEM9r3LXisTyJjST?si=MshqJUGAR6qpDJFFqrpggQ"
    }
  ]
};

export const HIT_REWIND: Playlist = {
  title: "Hit Rewind",
  description:
    "Still leading the charts, vintage hits from your record player days",
  image: "playlist_hitrewind.jpg",
  trackList: [
    {
      title: "What Hurts The Most",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/4bVuIlGQBMWS7vIhcx8Ae4?si=dsfPZyOgTnSbrdPsmyn51w"
    },
    {
      title: "What's My Age Again?",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/4LJhJ6DQS7NwE7UKtvcM52?si=B9lJiw73TdaR3Y9ifzXBMw"
    },
    {
      title: "Forever Young",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/1qli6fjEVdmMgssWy55eiZ?si=1MEKPLbeTE6jGQj-ugP-vw"
    }
  ]
};

export const NEW_BOOTS: Playlist = {
  title: "New Boots",
  description:
    "Leading the new generation in boots that were never made just for walking",
  image: "playlist_newboots.jpg",
  trackList: [
    {
      title: "Pray for You",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/3l8FWYAfYCutuiGgJndfos"
    },
    {
      title: "Goodbye Summer",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/1kmpcpEK0f56M5dkqNajRk"
    }
  ]
};

export const CHILL_HITS: Playlist = {
  title: "Chill Hits",
  description: "Kick back and relax to these calming melodies",
  image: "playlist_chillhits.jpg",
  trackList: [
    {
      title: "Good Vibrations",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/7tf64lNC31lWlTsih0nfZf?si=V41UIZc_QKmkBLOru5WnPQ"
    },
    {
      title: "Cool Kids",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/13P5rwmk2EsoFRIz9UCeh9?si=5DiI6SgDRjWcof5aYJ4TKQ"
    },
    {
      title: "Bitch Don't Kill My Vibe",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/712uvW1Vezq8WpQi38v2L9?si=O-R-ncrmSQSc0FCpnq8MbA"
    },
    {
      title: "Don't Worry, Be Happy",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/4hObp5bmIJ3PP3cKA9K9GY?si=oqZ7pUwsT2WonS-yFcOxtA"
    },
    {
      title: "Ice Ice Baby",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/3XVozq1aeqsJwpXrEZrDJ9?si=JBe-3gByTPG3eqL1StGFRg"
    },
    {
      title: "No Shoes, No Shirt, No Problem",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/208bYsadJ56km31IENM2qS?si=-RR7BGNHSd2Ixnt3wgASdw"
    }
  ]
};

export const NATURE_SOUNDS: Playlist = {
  title: "Nature Sounds",
  description: "Return to the harmonies of the wilderness wherever you may be",
  image: "playlist_naturesounds.jpg",
  fake: true,
  link: "https://open.spotify.com/playlist/37i9dQZF1DX4PP3DA4J0N8",
  trackList: [
    {
      title: "Praying",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/0jdny0dhgjUwoIp5GkqEaA?si=NBRv9kr0QY-hyERkUQA23Q"
    },
    {
      title: "Crazy Frog",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/3VQ955Eo5g8bi442lerau3"
    },
    {
      title: "What A Wonderful World",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/1Fzm9s6Fh1Eumj5tU4q20m?si=XTHeiCYBQfKbGU5OhuwXNw"
    },
    {
      title: "Heaven Is A Place On Earth",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/58mFu3oIpBa0HLNeJIxsw3"
    },
    {
      title: "Ain't No Mountain High Enough",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/7tqhbajSfrz2F7E1Z75ASX?si=lclaOOYIQYG6WO8cYHZuGQ"
    },
    {
      title: "This Land is Your Land",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/7CNaYAdLyi86kofGafReiT"
    }
  ]
};

export const SECRET_LIFE_OF_PETS: Playlist = {
  title: "Offficial Soundtrack: Secret Life of Pets",
  description: "Never go anywhere without your furry friends on your mind",
  image: "playlist_secretlifeofpets.jpg",
  trackList: [
    {
      title: "(How Much Is) That Doggie In The Window ft. Jasper",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/7bs4wDnaX0z7BTeM6lTcYA"
    },
    {
      title: "Howling At Nothing ft. Griffin",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/072XFpSbDJPCRxILkfUbxp"
    },
    {
      title: "Hound Dog ft. Thor",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/64Ny7djQ6rNJspquof2KoX"
    },
    {
      title: "Dog Days Are Over ft. Mari and Miso",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/1YLJVmuzeM2YSUkCCaTNUB?si=kJQceMUEQXKkJkOp6vgdDg"
    }
  ]
};

export const HAPPY_BIRTHDAY: Playlist = {
  title: "Happy Birthday",
  description: "Happy Birthday Champ!",
  image: "playlist_happybirthday.jpg",
  trackList: [
    {
      title: "Birthday",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/2xLOMHjkOK8nzxJ4r6yOKR?si=AR8XW2ggTly0tLfoM-H-1A"
    },
    {
      title: "Happy Birthday",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/01lewSOj9ZaXplh9TpNKL4?si=1_w8DqyJSIasUU_7fvMfjQ"
    },
    {
      title: "Birthday",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/1ABegtCPBMMJaMpfDyATjE"
    },
    {
      title: "Birthday Cake",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/4EfN6bixdOOgoLYR5C4cWo?si=RhHQHnGGR9O-9I6SG5uAsQ"
    },
    {
      title: "Birthday",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/345HZXxfL8S8HkVzytD1Pw?si=qQmYM3MGSdGcuWkNrUUwvw"
    },
    {
      title: "It's My Birthday",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/1pNcUexH7BdMFWFaxV3ltq?si=e6mx9nR6QIyoTxttrhn4qg"
    }
  ]
};

export const STUDY_BREAK: Playlist = {
  title: "Study Break",
  description: "The perfect tunes to keep your focus",
  image: "playlist_studybreak.jpg",
  trackList: [
    {
      title: "ABC",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/6wDviYDtmSDZ0S6TVMM9Vc"
    },
    {
      title: "The Longest Time",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/5DH7nDryMhpixm4G4B7RP9"
    },
    {
      title: "Me and Julio Down By The Schoolyard",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/6vxHp3CDNo0afgKGp2yi1E"
    },
    {
      title: "All Night",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/0dXNQ8dckG4eYfEtq9zcva"
    },
    {
      title: "Don't Stop 'Til You Get Enough",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/6AZZzlQD1FThgAcWIgu3g1"
    },
    {
      title: "Ways to Go",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/57nNNkgk768QVXq3uHxu5e"
    },
    {
      title: "Teach Me How to Dougie",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/61LtVmmkGr8P9I2tSPvdpf"
    },
    {
      title: "Daddy Lessons",
      artist: ARTISTS[Artists.PETER],
      link: "https://open.spotify.com/track/71OvX5NNLrmz7rpq1ANTQn"
    },
    {
      title: "School's Out",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/5Z8EDau8uNcP1E8JvmfkZe?si=WBXmtEiiQ8iEeRanE5iEEg"
    }
  ]
};

export const ALL_TIME_HITS: Playlist = {
  title: "All Time Hits",
  description:
    "Throughout the years: started on the charts, stayed on the charts.",
  image: "playlist_alltimehits.jpg",
  trackList: [
    {
      title: "Far Away",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/0ADZ5dmXhlfzjMw6lefoPl?si=GSZ8yvJkT-qQWnJ69ZgeZw"
    },
    {
      title: "Wake Me Up When September Ends",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/3ZffCQKLFLUvYM59XKLbVm?si=FmuxNKXQTSyNlLafUg5ddA"
    },
    {
      title: "We Are Family",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/5IKLwqBQG6KU6MP2zP80Nu?si=75Bicrc9QSKpzfQIAu6pMQ"
    },
    {
      title: "Uptown Girl",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/5zA8vzDGqPl2AzZkEYQGKh?si=79Ua892cQsumo9_Bq0JDSw"
    },
    {
      title: "Don't You (Forget About Me)",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/4aWn4NHlELpOehxsBaQeoe?si=gmKCo_QXRk69GQWpT4cSaQ"
    },
    {
      title: "Heart Of A Champion",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/5PYu9Jw9zWN7Iom9OtYmgU?si=0H7mcCetTVOZpTQ55kXpLQ"
    },
    {
      title: "I'll Never Love Again",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/3DKpA54hrFIdPN6AtL9HXa?si=AzL3N1pLREa1V_bppAwbCA"
    },
    {
      title: "Centerfield",
      artist: ARTISTS[Artists.PETER],
      link:
        "https://open.spotify.com/track/2iRfjwzPsooCskZrrlsgcC?si=XkWxtL_YS4WFC8IYuelV_A"
    }
  ]
};

export const RIVAL_2019_PLAYLIST: Playlist = {
  title: "Favorites",
  description: "Introducing Rival 2019!",
  image: "playlist_favorites.jpg",
  trackList: [
    {
      title: "",
      artist: ARTISTS[Artists.PETER],
      link: ""
    },
  ]
};





export const THIS_IS_JAN: Playlist = {
  title: "This is Jan Englund",
  description:
    "A soothing blend of bird songs and stress. Perfect soundtrack for a foggy, Pacific Northwest hike or for checking your work email while pretending to watch TV with your family. Don’t forget to turn the music off before you actually start to drive because who can think with that racket?!",
  image: "SpotCover_Jan.jpg",
  color: "rgb(193, 183, 235)",
  link: "https://open.spotify.com/playlist/21PMt3iT7hhSaYuyy2eGYJ",
  trackList: [
    {
      title: "I Love the Gates Foundation",
      artist: ARTISTS[Artists.JAN],
      link: ""
    },
    {
      title: "Jesus Take The Wheel",
      artist: ARTISTS[Artists.CARRIE_UNDERWOOD],
      link: "https://open.spotify.com/track/3lec3CzDPAxsZokPph5w87"
    },
    {
      title: "No mask, no problem.",
      artist: ARTISTS[Artists.IDAHO],
      link: ""
    },
    {
      title: "Gold Digger",
      artist: ARTISTS[Artists.KANYE_WEST],
      link: "https://open.spotify.com/track/1PS1QMdUqOal0ai3Gt7sDQ"
    },
    {
      title: "The Bird is the Word",
      artist: ARTISTS[Artists.JAN],
      link: ""
    },
    {
      title: "Woodpecker Noises",
      artist: ARTISTS[Artists.RED_HEADED_WOODPECKERS],
      link: ""
    },
    {
      title: "Loon Noises",
      artist: ARTISTS[Artists.LOONS],
      link: ""
    },
    {
      title: "Is this FaceTime?",
      artist: ARTISTS[Artists.JAN],
      link: ""
    },
    {
      title: "I Just Called to Say I Love You",
      artist: ARTISTS[Artists.JAN],
      link: ""
    },
    {
      title: "Photograph",
      artist: ARTISTS[Artists.NICKELBACK],
      link: "https://open.spotify.com/track/3hb2ScEVkGchcAQqrPLP0R"
    },
    {
      title: "I'm Like A Bird",
      artist: ARTISTS[Artists.NELLY_FURTADO],
      link: "https://open.spotify.com/track/4sUoWHVnJl8z3t4zdqf6xB"
    },
    {
      title: "Silence for Driving",
      artist: ARTISTS[Artists.JAN],
      link: ""
    },
        {
      title: "You're so Vain",
      artist: ARTISTS[Artists.CARLY_SIMON],
      link: "https://open.spotify.com/track/2DnJjbjNTV9Nd5NOa1KGba"
    },
  ]
};

export const THIS_IS_GRIFFIN: Playlist = {
  title: "This is Griffin Dog",
  description:
    "This playlist is like fresh peanut butter in a kong or like solid, unbroken ice on Lake Huron. No broken tails here - this playlist is all wags.",
  image: "SpotCover_Griffin.jpg",
  color: "rgb(245, 232, 179)",
  link: "https://open.spotify.com/playlist/3iYScMd3IolbsxPQmhuajw",
  trackList: [
    {
      title: "Who Let the Dogs Out",
      artist: ARTISTS[Artists.BAHA_MEN],
      link: "https://open.spotify.com/track/1H5tvpoApNDxvxDexoaAUo"
    },
    {
      title: "Dog Days Are Over",
      artist: ARTISTS[Artists.FLORENCE],
      link: "https://open.spotify.com/track/1YLJVmuzeM2YSUkCCaTNUB"
    },
    {
      title: "Did someone say park?",
      artist: ARTISTS[Artists.GRIFFIN],
      link: ""
    },
    {
      title: "Did someone say walk?",
      artist: ARTISTS[Artists.GRIFFIN],
      link: ""
    },
    {
      title: "Did someone say dinner?",
      artist: ARTISTS[Artists.GRIFFIN],
      link: ""
    },
    {
      title: "Hungry Like the Wolf",
      artist: ARTISTS[Artists.DURAN_DURAN],
      link: "https://open.spotify.com/track/2HuHopKnnuGQEpGb0CssCs"
    },
    {
      title: "Hungry Heart",
      artist: ARTISTS[Artists.BRUCE_SPRINGSTEEN],
      link: "https://open.spotify.com/track/1KsI8NEeAna8ZIdojI3FiT"
    },
    {
      title: "Seriously, did someone say dinner?",
      artist: ARTISTS[Artists.GRIFFIN],
      link: ""
    },
  ]
};

export const THIS_IS_PETER: Playlist = {
  title: "This is Peter Scott",
  description:
    'A mashup of K-pop, heavy metal, and crippling fear that you might be alone for the rest of your life. This is the ideal playlist for the man in the $10,000 suit. This music will have you saying “What pandemic??” as you jetset away from a secure government facility to ski with your friends on the west coast.',
  image: "SpotCover_Peter.jpg",
  color: "rgb(193, 201, 217)",
  link: "https://open.spotify.com/playlist/3Dp9ucSVDzcCOjORoOzvbl",
  trackList: [
    {
      title: "Party in the U.S.A.",
      artist: ARTISTS[Artists.MILEY_CYRUS],
      link: "https://open.spotify.com/track/5Q0Nhxo0l2bP3pNjpGJwV1"
    },
    {
      title: "Empire State Of Mind",
      artist: ARTISTS[Artists.JAY_Z],
      link: "https://open.spotify.com/track/2igwFfvr1OAGX9SKDCPBwO"
    },
    {
      title: "Hopeless Wanderer",
      artist: ARTISTS[Artists.MUMFORD_AND_SONS],
      link: "https://open.spotify.com/track/7rME25EmgXGVPQqKtU4CIK"
    },
    {
      title: "Foreign Agent",
      artist: ARTISTS[Artists.PETER],
      link: ""
    },
    {
      title: "Rich Girl",
      artist: ARTISTS[Artists.HALL_AND_OATES],
      link: "https://open.spotify.com/track/0qRR9d89hIS0MHRkQ0ejxX"
    },
    {
      title: "Money Trees",
      artist: ARTISTS[Artists.KENDRICK_LAMAR],
      link: "https://open.spotify.com/track/2HbKqm4o0w5wEeEFXm2sD4"
    },
    {
      title: "Money Bags",
      artist: ARTISTS[Artists.PETER_THIEL],
      link: ""
    },
    {
      title: "Leaving, On a Jet Plane",
      artist: ARTISTS[Artists.JOHN_DENVER],
      link: "https://open.spotify.com/track/3D8dwH690MXQRhtIZTSS9c"
    },
    {
      title: "IDGAF",
      artist: ARTISTS[Artists.DUA_LIPA],
      link: "https://open.spotify.com/track/76cy1WJvNGJTj78UqeA5zr"
    },
    {
      title: "I'm Just a Kid",
      artist: ARTISTS[Artists.SIMPLE_PLAN],
      link: "https://open.spotify.com/track/6otiaV2fagE3s8IvP6WkwG"
    },
    {
      title: "There's Nothing Holdin' Me Back",
      artist: ARTISTS[Artists.SHAWN_MENDES],
      link: "https://open.spotify.com/track/7JJmb5XwzOO8jgpou264Ml"
    },
    {
      title: "Livin' la Vida Loca",
      artist: ARTISTS[Artists.RICKY_MARTIN],
      link: "https://open.spotify.com/track/0Ph6L4l8dYUuXFmb71Ajnd"
    },
    {
      title: "Surfin' U.S.A.",
      artist: ARTISTS[Artists.BEACH_BOYS],
      link: "https://open.spotify.com/track/2EPr9Wx7e1cpGEbNAtuN7x"
    },
    {
      title: "Billionaire",
      artist: ARTISTS[Artists.TRAVIE_MCCOY],
      link: "https://open.spotify.com/track/2M9ULmQwTaTGmAdXaXpfz5"
    },
  ]
};

export const THIS_IS_ANNA: Playlist = {
  title: "This is Anna Scott",
  description:
    'A powerful, sassy playlist perfect for any current or former CEO. The ideal soundtrack for selling out. These ballads will move you from triumph, to tears, to a giggly-baby voiced mood saying “Yaaaan.”',
  image: "SpotCover_Anna.jpg",
  color: "rgb(171, 222, 212)",
  link: "https://open.spotify.com/playlist/5lEXBcqzkEWYkaC2krNIxr",
  trackList: [
    {
      title: "Respect",
      artist: ARTISTS[Artists.ARETHA_FRANKLIN],
      link: "https://open.spotify.com/track/7s25THrKz86DM225dOYwnr"
    },
    {
      title: "Sell Out",
      artist: ARTISTS[Artists.REEL_BIG_FISH],
      link: "https://open.spotify.com/track/6SJ1hWFVBIiUgGgHKvwXCQ"
    },
    {
      title: "Big Oil",
      artist: ARTISTS[Artists.TROPOSPHERE_MONITORING],
      link: ""
    },
    {
      title: "Parlez-vous Anglais?",
      artist: ARTISTS[Artists.ANNA],
      link: ""
    },
    {
      title: "If I Were a Boy",
      artist: ARTISTS[Artists.BEYONCE],
      link: "https://open.spotify.com/track/26NX1wPt1TRCH536yocd6i"
    },
    {
      title: "Unfinished Business",
      artist: ARTISTS[Artists.MUMFORD_AND_SONS],
      link: "https://open.spotify.com/track/0Dta2GzHUGQbARAKnQrWDk"
    },
    {
      title: "Just Got Paid",
      artist: ARTISTS[Artists.SIGALA],
      link: "https://open.spotify.com/track/0v3BMLcwUmAwyqJaf5997o"
    },
    {
      title: "Honey, I'm Good.",
      artist: ARTISTS[Artists.ANDY_GRAMMER],
      link: "https://open.spotify.com/track/4MflGTO2ZTcSQ12bWcyRgI"
    },
    {
      title: "What a Girl Wants",
      artist: ARTISTS[Artists.CHRISTINA_AGUILERA],
      link: "https://open.spotify.com/track/5bGmuxShUba9maPswDnhCs"
    },
    {
      title: "C'est La Vie",
      artist: ARTISTS[Artists.B_WITCHED],
      link: "https://open.spotify.com/track/0P6CPJrNJ1Zt755CQTWg3S"
    },
  ]
};

export const THIS_IS_RIDG: Playlist = {
  title: "This is Ridg Scott",
  description:
    "A generic mashup of Beach Boys and Alvin and the Chipmunks Christmas hits, ideal for any older white retirees getting driven by their Toyota Rav4s. No need to focus on the road - set your follow distance to medium and sing along to these jams!",
  image: "SpotCover_Ridg.jpg",
  color: "rgb(179, 210, 232)",
  link: "https://open.spotify.com/playlist/7qmiwWztndTQb0XGvWhFZv",
  trackList: [
    {
      title: "Two Edged Sword",
      artist: ARTISTS[Artists.DONALD_TRUMP],
      link: ""
    },
    {
      title: "It's Five O'Clock Somewhere",
      artist: ARTISTS[Artists.ALAN_JACKSON],
      link: "https://open.spotify.com/track/5oHHMDcVOmPSFrCgdbHPdb"
    },
    {
      title: "This Watch Tells History",
      artist: ARTISTS[Artists.RIDG],
      link: ""
    },
    {
      title: "Whiskey Glasses",
      artist: ARTISTS[Artists.MORGAN_WALLEN],
      link: "https://open.spotify.com/track/6foY66mWZN0pSRjZ408c00"
    },
    {
      title: "Beer For My Horses",
      artist: ARTISTS[Artists.TOBY_KEITH],
      link: "https://open.spotify.com/track/7E2DqvnVtbIrFrL5X6YH9Q"
    },
    {
      title: "Buy Me a Boat",
      artist: ARTISTS[Artists.CHRIS_JANSON],
      link: "https://open.spotify.com/track/2WKLUgklz0VDX5bKTZ3eYD"
    },
    {
      title: "Pretty Fly (For A White Guy)",
      artist: ARTISTS[Artists.THE_OFFSPRING],
      link: "https://open.spotify.com/track/3SFXsFpeGmBTtQvKiwYMDA"
    },
    {
      title: "Sail",
      artist: ARTISTS[Artists.AWOLNATION],
      link: "https://open.spotify.com/track/5xhQChGGhKLWqBqX4XhtYE"
    },
    {
      title: "Come Sail Away",
      artist: ARTISTS[Artists.STYX],
      link: "https://open.spotify.com/track/0PsbWiVtix5FoTZ1s00mEl"
    },
    {
      title: "A Hard Day's Night",
      artist: ARTISTS[Artists.THE_BEATLES],
      link: "https://open.spotify.com/track/5J2CHimS7dWYMImCHkEFaJ"
    },
    {
      title: "If You've Got The Money, I've Got the Time",
      artist: ARTISTS[Artists.WILLIEN_NELSON],
      link: "https://open.spotify.com/track/7aVapcvsbFYYmcQvhkc8Rc"
    },
    {
      title: "(Sittin' On) the Dock of the Bay",
      artist: ARTISTS[Artists.OTIS_REDDING],
      link: "https://open.spotify.com/track/3zBhihYUHBmGd2bcQIobrF"
    },
  ]
};

export const THIS_IS_YAN: Playlist = {
  title: "This is Yan Azdoud",
  description:
    "The high-powered hits don’t stop in this country music meets cooking show playlist. Whether you’re driving a rented F150 down highways in Texas, or getting that hot new tattoo from your local vending machine - this playlist will butter your biscuit. And all of your other food.",
  image: "SpotCover_Yan.jpg",
  color: "rgb(183, 231, 247)",
  link: "https://open.spotify.com/playlist/6QdGqmwwsu8mMFtKCozNPA",
  trackList: [
    {
      title: "Hips Don't Lie",
      artist: ARTISTS[Artists.SHAKIRA],
      link: "https://open.spotify.com/track/3ZFTkvIE7kyPt6Nu3PEa7V"
    },
    {
      title: "Body Like A Back Road",
      artist: ARTISTS[Artists.SAM_HUNT],
      link: "https://open.spotify.com/track/7mldq42yDuxiUNn08nvzHO"
    },
    {
      title: "Morocco",
      artist: ARTISTS[Artists.MOON_TAXI],
      link: "https://open.spotify.com/track/0ecM7uGyjgJnBliXS2fPP9"
    },
    {
      title: "Gimme the Butter Baby",
      artist: ARTISTS[Artists.ANNA],
      link: ""
    },
    {
      title: "Drive My Car",
      artist: ARTISTS[Artists.THE_BEATLES],
      link: "https://open.spotify.com/track/06ypiqmILMdVeaiErMFA91"
    },
    {
      title: "Take Me Home, Country Roads",
      artist: ARTISTS[Artists.JOHN_DENVER],
      link: "https://open.spotify.com/track/39q7xibBdRboeMKUbZEB6g"
    },
    {
      title: "Love Is Blind",
      artist: ARTISTS[Artists.COLEMAN_HELL],
      link: "https://open.spotify.com/track/4qk35C5lGrStA7N8XiExHt"
    },
    {
      title: "Don't Take The Money",
      artist: ARTISTS[Artists.BLEACHERS],
      link: "https://open.spotify.com/track/3ySU5vwQB33iGulwcUL9qQ"
    },
    {
      title: "Road Trippin'",
      artist: ARTISTS[Artists.DAN_AND_SHAY],
      link: "https://open.spotify.com/track/2IOvJJrN6RajoMOd9TyGra"
    },
    {
      title: "Heavy Bones",
      artist: ARTISTS[Artists.ARIEL_FERNANDEZ],
      link: ""
    },
    {
      title: "My Chick Bad",
      artist: ARTISTS[Artists.LUDACRIS],
      link: "https://open.spotify.com/track/3bMNprrp2JDKZsGbiXpsJl"
    },
  ]
};

export const THIS_IS_JORDAN: Playlist = {
  title: "This is Jordan O`Neill",
  description:
    "Backstreet Boys meets dad bod in this party-mix playlist. Crank the volume up and you won’t even notice the disc-golf chains clanging in the backyard as you sing along. Whether it’s your once-weekly 30-minute meeting, or trying to master that perfect man-bun, blast these bops to get you through those stressful moments in your life.",
  image: "SpotCover_Jordan.jpg",
  color: "rgb(240, 229, 170)",
  link: "https://open.spotify.com/playlist/15F6BTWx2I3TEU6BK5v1sI",
  trackList: [
    {
      title: "Work from Home",
      artist: ARTISTS[Artists.FIFTH_HARMONY],
      link: "https://open.spotify.com/track/4tCtwWceOPWzenK2HAIJSb"
    },
    {
      title: "How Do You Sleep?",
      artist: ARTISTS[Artists.SAM_SMITH],
      link: "https://open.spotify.com/track/6b2RcmUt1g9N9mQ3CbjX2Y"
    },
    {
      title: "Shut Up and Dance",
      artist: ARTISTS[Artists.WALK_THE_MOON],
      link: "https://open.spotify.com/track/4kbj5MwxO1bq9wjT5g9HaA"
    },
    {
      title: "Long Hair Don't Care",
      artist: ARTISTS[Artists.JORDAN],
      link: ""
    },
    {
      title: "She Hates Me",
      artist: ARTISTS[Artists.PUDDLE_OF_MUDD],
      link: "https://open.spotify.com/track/16DhvbuyvJob4Q9GHNYu2n"
    },
    {
      title: "Sit Still, Look Pretty",
      artist: ARTISTS[Artists.DAYA],
      link: "https://open.spotify.com/track/0EGuSSpuu9wmHCtvb4PdLO"
    },
    {
      title: "Crazy for This Girl",
      artist: ARTISTS[Artists.EVAN_AND_JARON],
      link: "https://open.spotify.com/track/2gdt70w8DOCWGq1HdkgSUd"
    },
    {
      title: "Disc Golf Podcast",
      artist: ARTISTS[Artists.DISC_GOLFERS],
      link: ""
    },
    {
      title: "Girls Just Want to Have Fun",
      artist: ARTISTS[Artists.CYNDI_LAUPER],
      link: "https://open.spotify.com/track/4y1LsJpmMti1PfRQV9AWWe"
    },
    {
      title: "Wake Me Up Before You Go-Go",
      artist: ARTISTS[Artists.WHAM],
      link: "https://open.spotify.com/track/0ikz6tENMONtK6qGkOrU3c"
    },
    {
      title: "Dancing Queen",
      artist: ARTISTS[Artists.ABBA],
      link: "https://open.spotify.com/track/0GjEhVFGZW8afUYGChu3Rr"
    },
  ]
};

export const THIS_IS_SARA: Playlist = {
  title: "This is Sara Scott",
  description:
    "For those of you that do not understand the meaning of relaxation--this productivity-enhancing playlist uniquely allows you to play songs at 1.75x speed. Have 4 free minutes between activities? Try to bake a cake! Don't even worry about cleaning those dishes!",
  image: "SpotCover_Sara.jpg",
  color: "rgb(155, 201, 161)",
  link: "https://open.spotify.com/playlist/15F6BTWx2I3TEU6BK5v1sI",
  trackList: [
    {
      title: "You Need To Calm Down",
      artist: ARTISTS[Artists.TAYLOR_SWIFT],
      link: "https://open.spotify.com/track/6RRNNciQGZEXnqk8SQ9yv5"
    },
    {
      title: "Is this a business expense?",
      artist: ARTISTS[Artists.SARA],
      link: ""
    },
    {
      title: "The 3 R's",
      artist: ARTISTS[Artists.JACK_JOHNSON],
      link: "https://open.spotify.com/track/1v6WwQtShGnXe5Fc0xyjHO"
    },
    {
      title: "That Don't Impress Me Much",
      artist: ARTISTS[Artists.SHANIA_TWAIN],
      link: "https://open.spotify.com/track/4FUfoWMypAyWbOavmYyeNu"
    },
    {
      title: "How to Save a Life",
      artist: ARTISTS[Artists.THE_FRAY],
      link: "https://open.spotify.com/track/5fVZC9GiM4e8vu99W0Xf6J"
    },
    {
      title: "I Want It That Way",
      artist: ARTISTS[Artists.BACKSTREET_BOYS],
      link: "https://open.spotify.com/track/47BBI51FKFwOMlIiX6m8ya"
    },
    {
      title: "Gives You Hell",
      artist: ARTISTS[Artists.ALL_AMERICAN_REJECTS],
      link: "https://open.spotify.com/track/6ihL9TjfRjadfEePzXXyVF"
    },
    {
      title: "Hero",
      artist: ARTISTS[Artists.ENRIQUE_IGLESIAS],
      link: "https://open.spotify.com/track/24Gxm7Eh7tO83E26LG0CsY"
    },
    {
      title: "Every Little Thing She Does Is Magic",
      artist: ARTISTS[Artists.THE_POLICE],
      link: "https://open.spotify.com/track/44aTAUBF0g6sMkMNE8I5kd"
    },
        {
      title: "Sweet Disposition",
      artist: ARTISTS[Artists.THE_TEMPER_TRAP],
      link: "https://open.spotify.com/track/5RoIXwyTCdyUjpMMkk4uPd"
    },
    {
      title: "All I Do Is Win",
      artist: ARTISTS[Artists.DJ_KHALED],
      link: "https://open.spotify.com/track/75FT1IPlCYCs5DAYbR9mjE"
    }
  ]
};

export const YEAR_IN_REVIEW: Playlist = {
  title: "Year In Review",
  description: "",
  image: "YearInReview.jpg",
  color: "rgb(155, 201, 161)",
  link: "https://open.spotify.com/playlist/3zcvZEa4j9Wve0pUWn3zxe",
  trackList: [],
  fake: true,
};

export const HAMILTON: Playlist = {
  title: "Hamilton",
  description: "",
  image: "Hamilton.jpg",
  color: "rgb(155, 201, 161)",
  link: "https://open.spotify.com/album/1kCHru7uhxBUdzkm4gzRQc?si=QSlhXt06RTWaCXqjNV2i7w",
  trackList: [],
  fake: true,
};

export const EVAN_HANSEN: Playlist = {
  title: "Dear Evan Hansen",
  description: "",
  image: "EvanHansen.jpg",
  color: "rgb(155, 201, 161)",
  link: "https://open.spotify.com/album/0LhDyJXelg31FKLW5GDcKi?si=GkGjGr9_S3SzmAjxx-k8wQ",
  trackList: [],
  fake: true,
};

export const WILLIE_NELSON_PLAYLIST: Playlist = {
  title: "This is Willie Nelson",
  description: "",
  image: "WillieNelson.jpg",
  color: "rgb(155, 201, 161)",
  link: "https://open.spotify.com/playlist/37i9dQZF1DWWIjhBGHJI0J",
  trackList: [],
  fake: true,
};

export const BEACH_BOYS: Playlist = {
  title: "This is Beach Boys",
  description: "",
  image: "BeachBoys.jpg",
  color: "rgb(155, 201, 161)",
  link: "https://open.spotify.com/playlist/37i9dQZF1DXaZan2kzvaVc",
  trackList: [],
  fake: true,
};

export const SAD_SONGS: Playlist = {
  title: "Sad Songs",
  description: "",
  image: "SadSongs.jpg",
  color: "rgb(155, 201, 161)",
  link: "https://open.spotify.com/playlist/7ABD15iASBIpPP5uJ5awvq",
  trackList: [],
  fake: true,
};

export const PLAYLISTS: Playlist[] = [
  RIVAL_2019_PLAYLIST,
  NEW_BOOTS,
  HIT_REWIND,
  THE_ONES_THAT_GOT_AWAY,
  FRESH_FINDS,
  ALL_TIME_HITS,
  LADIES_EIGHTIES,
  HAPPY_BIRTHDAY,
  KIDS_BOP_19,
  THIS_IS_PAVAROTTI,
  EMO_FOREVER,
  MORNING_COMMUTE,
  STUDY_BREAK,
  THIS_IS_PITBULL,
  CHILL_HITS,
  NATURE_SOUNDS,
  SECRET_LIFE_OF_PETS,
  THIS_IS_JAN,
  THIS_IS_GRIFFIN,
  THIS_IS_ANNA,
  THIS_IS_RIDG,
  THIS_IS_PETER,
  THIS_IS_YAN,
  THIS_IS_JORDAN,
  THIS_IS_SARA,
];

export const HEAVY_ROTATION: Category = {
  title: "Your heavy rotation",
  playlists: [THIS_IS_JAN, THIS_IS_RIDG, THIS_IS_ANNA, THIS_IS_YAN]
};


export const FRESH_NEW_MUSIC: Category = {
  title: "Fresh new music",
  playlists: [THIS_IS_JORDAN, THIS_IS_YAN, THIS_IS_GRIFFIN,]
};

export const YEARS_IN_REWIND: Category = {
  title: "Year in review",
  playlists: [YEAR_IN_REVIEW]
};

export const MORE_OF_WHAT_YOU_LIKE: Category = {
  title: "More of what you like",
  playlists: [
    THIS_IS_GRIFFIN,
  ]
};

export const OLDIES_BUT_GOODIES: Category = {
  title: "Oldies but goodies",
  playlists: [
    THIS_IS_JAN,
    THIS_IS_RIDG,
  ]
};
export const CHRISTMAS_CLASSICS: Category = {
  title: "Christmas classics",
  playlists: [
    THIS_IS_ANNA,
    THIS_IS_SARA,
    THIS_IS_PETER,
  ]
};
export const RECENTLY_PLAYED: Category = {
  title: "Recently played",
  playlists: [HAMILTON, BEACH_BOYS, EVAN_HANSEN, NATURE_SOUNDS, WILLIE_NELSON_PLAYLIST, SAD_SONGS]
};

export const CATEGORIES: Category[] = [
  CHRISTMAS_CLASSICS,
  OLDIES_BUT_GOODIES,
  FRESH_NEW_MUSIC,
  YEARS_IN_REWIND,
  RECENTLY_PLAYED,
];

import { Instructors, InstructorType, PelotonNavTab } from "../data/PelotonData";

export interface PelotonState {
    selectedTab: PelotonNavTab;
    selectedInstuctor: keyof typeof Instructors | undefined,
    selectedClassType: InstructorType;
}

export const INITIAL_PELOTON_STATE: PelotonState = {
    selectedTab: PelotonNavTab.HOME,
    selectedInstuctor: undefined,
    selectedClassType: InstructorType.ALL,
};

export const getInitialPelotonState = (): PelotonState => INITIAL_PELOTON_STATE;
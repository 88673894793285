import { Card, CardList } from "@blueprintjs/core";
import * as React from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import styles from "../../styles/buzzfeed/ResultListBody.module.scss";
import { CONTESTANTS, Contestants } from "../data/BuzzFeedData";
import { BuzzFeedActions } from "../state/BuzzFeedActions";

export const ResultListBody = React.memo(function ResultListBodyInternal() {
  const dispatch = useDispatch();

  const selectContestant = useCallback(
    (contestant: keyof typeof Contestants) => {
      dispatch(BuzzFeedActions.selectContestant(contestant));
    },
    [dispatch]
  );

  return (
    <div className={styles.resultListBody}>
      <div className={styles.title}>
        Okay fine, which Scott are you most like?
      </div>
      <CardList compact bordered>
        {CONTESTANTS.map((contestant) => (
          <Card
            key={contestant.key}
            interactive
            onClick={() => selectContestant(contestant.key)}
          >
            {contestant.name}
          </Card>
        ))}
      </CardList>
    </div>
  );
});

import * as React from "react";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { SCOTTIFY_RULES } from "../data/Rules";
import styles from "../../styles/scottify/ScottifyRules.module.scss";

// eslint-disable-next-line no-redeclare
export default class ScottifyRules extends React.Component<{}> {
  render() {
    return (
      <div
        className={classNames(styles.scottifyRules, {
          [styles.mobile]: isMobile
        })}
      >
        <div className={styles.rulesBody}>
          {SCOTTIFY_RULES.map(rule => (
            <div className={styles.ruleContainer} key={rule.rule}>
              <div className={styles.ruleImageContainer}>
                <img className={styles.ruleImage} src={rule.image} alt=""/>
              </div>
              <div className={styles.ruleText}>
                {rule.heading && (
                  <div className={styles.ruleHeader}>{rule.heading}</div>
                )}
                <div className={styles.ruleDescription}>{rule.rule}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

import * as React from "react";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import pelotonRulesStyles from "../../styles/peloton/PelotonRules.module.scss";
import { PELOTON_RULES } from "../data/Rules";


declare namespace PelotonRules {
  export interface Props {}

  export interface State {}
}

// eslint-disable-next-line no-redeclare
export default class PelotonRules extends React.Component<
PelotonRules.Props,
PelotonRules.State
> {
  render() {
    return (
      <div
        className={classNames(pelotonRulesStyles.pelotonRules, {
          [pelotonRulesStyles.mobile]: isMobile
        })}
      >
        <div className={pelotonRulesStyles.rulesBody}>
          {PELOTON_RULES.map(rule => (
            <div className={pelotonRulesStyles.ruleContainer} key={rule.rule}>
              <div className={pelotonRulesStyles.ruleImageContainer}>
                <img className={pelotonRulesStyles.ruleImage} src={rule.image} alt=""/>
              </div>
              <div className={pelotonRulesStyles.ruleText}>
                {rule.heading && (
                  <div className={pelotonRulesStyles.ruleHeader}>{rule.heading}</div>
                )}
                <div className={pelotonRulesStyles.ruleDescription}>{rule.rule}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

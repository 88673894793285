import classNames from "classnames";
import * as React from "react";
import { isMobile } from "react-device-detect";
import instructorViewStyles from "../../styles/peloton/InstructorView.module.scss";
import { Instructor } from "../data/PelotonData";

declare namespace InstructorView {
  export interface Props {
    instructor: Instructor;
  }
}



// eslint-disable-next-line no-redeclare
export default class InstructorView extends React.Component<InstructorView.Props> {

private renderBody = () => {
    const { instructor } = this.props;
    return (
        <React.Fragment>
        <div 
        className={classNames(instructorViewStyles.instructorViewContainer, instructorViewStyles.introContainer)}
        style={{
            backgroundImage: "linear-gradient(-12deg, #F5F7F9 0% 50%, #FFFFFF 50% 100%)"
        }}
         >
            <div 
                className={classNames(instructorViewStyles.introImageContainer, {[instructorViewStyles.contain]: instructor.key === "JORDAN" || instructor.key === "ANNA"})}
                style={{ backgroundImage: "url(" + instructor.bioImgUrl + ")" }}
            />
            <div className={instructorViewStyles.introTextContainer}>
                <div className={instructorViewStyles.title}>{instructor.name}</div>
                <div className={instructorViewStyles.subtitle}>Peloton Instructor</div>
                <div className={instructorViewStyles.text}>{instructor.bio}</div>
            </div>
        </div>
        <div 
            className={classNames(instructorViewStyles.instructorViewContainer, instructorViewStyles.quoteContainer)}
            style={{ backgroundImage: "url(" + instructor.quoteImgUrl + ")" }}
        >
            {instructor.quote != null && <div className={instructorViewStyles.quoteSymbol}>&#8220;</div>}
            {instructor.quote != null && <div className={instructorViewStyles.instructorQuote}>{instructor.quote}</div>}
            {instructor.quote != null && <div className={instructorViewStyles.instructorName}>{instructor.quoteSignature ?? instructor.name}</div>}
        </div>
    </React.Fragment>
    )
}

private renderMobileBody = () => {
    const { instructor } = this.props;
    return (
        <React.Fragment>
            <div 
                className={instructorViewStyles.mobileIntroImageContainer}
                style={{
                    backgroundImage: "linear-gradient(-12deg, #F5F7F9 0% 50%, #FFFFFF 50% 100%)"
                }}
            >
                <div 
                    className={instructorViewStyles.mobileIntroImageContainer}
                    style={{ backgroundImage: "url(" + instructor.bioImgUrl + ")" }}
                />
            </div>
            <div className={instructorViewStyles.mobileIntroTextContainer}>
                <div className={instructorViewStyles.mobileTitle}>{instructor.name}</div>
                <div className={instructorViewStyles.mobileSubtitle}>Peloton Instructor</div>
                <div className={instructorViewStyles.mobileText}>{instructor.bio}</div>
            </div>
            <div 
                className={instructorViewStyles.mobileQuoteContainer}
                style={{ backgroundImage: "url(" + instructor.quoteImgUrl + ")" }}
            >
                {instructor.quote != null && <div className={instructorViewStyles.quoteSymbol}>&#8220;</div>}
                {instructor.quote != null && <div className={instructorViewStyles.instructorQuote}>{instructor.quote}</div>}
                {instructor.quote != null && <div className={instructorViewStyles.instructorName}>{instructor.quoteSignature ?? instructor.name}</div>}
            </div>
        </React.Fragment>
    )
}

  render() {
    return (
        <div className={classNames(instructorViewStyles.instructorView, {[instructorViewStyles.mobile]: isMobile})}>
            {isMobile ? this.renderMobileBody() : this.renderBody()}
        </div>
    );
  }
}

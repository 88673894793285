import { TypedAction } from "redoodle";
import { ScottflixNavTab } from "../data/ScotflixData";

export const PREFIX = "@scott-secret-santa//scottflix";

export const selectTab = TypedAction.define(`${PREFIX}//selectTab`)<
ScottflixNavTab
>();

export const ScottflixActions = {
    selectTab
}
import { Colors, Icon, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Tooltip2 } from "@blueprintjs/popover2";
import classNames from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/alone/AloneHome.module.scss";
import bioStyles from "../../styles/alone/ContestantBioBody.module.scss";
import { CONTESTANTS } from "../data/AloneData";
import { getAloneSelectedContestant } from "../state/AloneSelectors";

export const ContestantBioBody = React.memo(
  function ContestantBioBodyInternal() {
    const selectedContestant = useSelector(getAloneSelectedContestant);
    const contestant =
      selectedContestant != null
        ? CONTESTANTS.find((c) => c.key === selectedContestant)
        : undefined;

    if (contestant == null) {
      return <div />;
    }

    return (
      <div className={styles.body}>
        <div className={styles.bodyHeader}></div>
        <div className={bioStyles.primaryPicSection}>
          <div
            className={bioStyles.pic}
            style={{ backgroundImage: "url(" + contestant.primaryImgUrl + ")" }}
          />
        </div>
        <div className={bioStyles.section}>
          <div className={bioStyles.name}>{contestant.name}</div>
          <div className={bioStyles.season}>Season: Scotts vs. Nots</div>
        </div>
        <div className={bioStyles.section}>
          <div className={bioStyles.sectionHeader}>Strategy</div>
          <div className={bioStyles.bio}>{contestant.bio}</div>
        </div>
        <div className={bioStyles.section}>
          <div className={bioStyles.sectionHeader}>
            Survival Items
            <Tooltip2
              content={
                "Contestants were asked to choose 10 items to bring on their survival journey"
              }
              position={Position.RIGHT}
            >
              <Icon
                icon={IconNames.INFO_SIGN}
                color={Colors.GRAY5}
                className={bioStyles.info}
                size={10}
              />
            </Tooltip2>
          </div>
          <div className={bioStyles.itemsList}>
            {contestant.items.map((item, index) => (
              <div
                key={"item" + index}
                className={classNames({
                  [bioStyles.itemStrike]:
                    contestant.items.length > 10 && index === 0,
                })}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
        <div className={bioStyles.secondaryPicSection}>
          <div
            className={bioStyles.pic}
            style={{
              backgroundImage: "url(" + contestant.secondaryImgUrl + ")",
            }}
          />
          <div
            className={bioStyles.pic}
            style={{
              backgroundImage: "url(" + contestant.tertiaryImgUrl + ")",
            }}
          />
        </div>
      </div>
    );
  }
);

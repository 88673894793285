import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { store } from "./state/store";
import { App } from './App';

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

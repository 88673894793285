import * as React from "react";
import pelotonHomeStyles from "../../styles/peloton/PelotonHome.module.scss";
import { PelotonNavTab } from "../data/PelotonData";

declare namespace PelotonHeaderBar {
    export interface Props {
      onClick: (tab: PelotonNavTab) => void;
    }
  }
  

// eslint-disable-next-line no-redeclare
export default class PelotonHeaderBar extends React.Component<PelotonHeaderBar.Props> {
  render() {
    return (
        <div className={pelotonHomeStyles.header}>
            <div className={pelotonHomeStyles.headerLogo}>
                <img
                    className={pelotonHomeStyles.headerLogoImage}
                    src="/img/peloton/PelotonLogo_white.png"
                    alt=""
                    onClick={() => this.props.onClick(PelotonNavTab.HOME)}
                />
            </div>
            <div className={pelotonHomeStyles.navContainer}>
                <div className={pelotonHomeStyles.navItem} onClick={() => this.props.onClick(PelotonNavTab.HOME)}>Home</div>
                <div className={pelotonHomeStyles.navItem} onClick={() => this.props.onClick(PelotonNavTab.RULES)}>Rules</div>
            </div>
        </div>
    );
  }
}

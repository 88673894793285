import * as React from "react";
import TrackRowMobile from "./TrackRowMobile";
import { IconNames } from "@blueprintjs/icons";
import { Colors, Icon } from "@blueprintjs/core";
import { CATEGORIES, Playlist, RIVAL_2019_PLAYLIST } from "../data/ScottifyData";
import AlbumCoverMobile from "./AlbumCoverMobile";
import styles from "../../styles/scottify/ScottifyMobileBody.module.scss";

declare namespace ScottifyMobileBody {
  export interface Props {
    playlist: Playlist;
    selectPlaylist: (playlist: Playlist) => void;
    nextPlaylist: () => void;
    previousPlaylist: () => void;
  }

  export interface State {
    mode: ScottifyMobileBodyMode;
  }
}

export enum ScottifyMobileBodyMode {
  PLAYLIST = "PLAYLIST",
  LIST = "LIST"
}

// eslint-disable-next-line no-redeclare
export default class ScottifyMobileBody extends React.Component<
ScottifyMobileBody.Props,
ScottifyMobileBody.State
> {
  state: ScottifyMobileBody.State = {
    mode: ScottifyMobileBodyMode.LIST
  };

  private scrollContainerRef = React.createRef<any>();

  render() {
    return (
      <div
        className={styles.scottifyBodyMobile}
        ref={this.scrollContainerRef}
      >
        {this.state.mode === ScottifyMobileBodyMode.LIST
          ? this.renderListView()
          : this.renderPlaylistView()}
      </div>
    );
  }

  private handleClickPlay = () => {
    window.open(this.props.playlist.link, "_blank");
  };

  private renderListView = () => {
    return (
      <React.Fragment>
        {CATEGORIES.map(category => {
          return (
            <div className={styles.categoryContainer} key={category.title}>
              <div className={styles.categoryTitle}>{category.title}</div>
              <div className={styles.categoryDescription}>{category.description}</div>
              <div className={styles.playlistsContainer}>
                {category.playlists.map((p, index) => (
                  <AlbumCoverMobile
                    playlist={p}
                    isSelected={false}
                    onClick={this.handleSelectPlaylist}
                    key={"playlist_" + index}
                    includeFullTitle={true}
                    large={false}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  private renderPlaylistView = () => {
    const { playlist } = this.props;

    // const sortedTracks = _.sortBy(
    //   playlist.trackList,
    //   track => track.artist.jersey
    // );
    const sortedTracks = playlist.trackList;

    return (
      <div className={styles.mobilePlaylistView}>
        <div className={styles.topButtonsContainer}>
          <Icon
            icon={IconNames.CHEVRON_LEFT}
            onClick={() => this.handleChangeMode(ScottifyMobileBodyMode.LIST)}
            iconSize={20}
            color={Colors.WHITE}
          />
        </div>
        <div className={styles.playlistScrollContainer} style={{backgroundColor: playlist.color || " rgb(162, 188, 142)"}}>
          <AlbumCoverMobile
            playlist={playlist}
            includeFullTitle={false}
            isSelected={false}
            onClick={() => {}}
            large={true}
          />
          <div className={styles.playlistDesc}>{playlist.description}</div>
          <div className={styles.controlContainer}>
            <Icon className={styles.controlPlay} icon={IconNames.PLAY} color={Colors.WHITE} iconSize={24} onClick={this.handleClickPlay} />
          </div>
          <div className={styles.mobileTracksList}>
            {sortedTracks.map((track, index) => (
              <TrackRowMobile
                key={"track_" + index}
                track={track}
                specialTrack={playlist === RIVAL_2019_PLAYLIST}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  private handleChangeMode = (newMode: ScottifyMobileBodyMode) => {
    this.setState({ mode: newMode });
  };

  private handleSelectPlaylist = (playlist: Playlist) => {
    if (playlist.fake) {
      window.open(
        playlist.link,
        "_blank"
      );
      return;
    }
    
    this.props.selectPlaylist(playlist);
    this.handleChangeMode(ScottifyMobileBodyMode.PLAYLIST);
  };
}

import {
  Classes,
  Icon,
  Menu,
  MenuItem,
  Popover,
  Position,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import styles from "../../styles/scottify/TrackRowMobile.module.scss";
import { getDisplayNameForArtist } from "../data/Artists";
import { Track } from "../data/ScottifyData";

declare namespace TrackRowMobile {
  export interface Props {
    track: Track;
    specialTrack?: boolean;
  }
}

// eslint-disable-next-line no-redeclare
export default class TrackRowMobile extends React.Component<TrackRowMobile.Props> {
  render() {
    const { track, specialTrack } = this.props;
    const num = 1; // bmoore to fix

    return (
      <div className={styles.trackRowMobile}>
        <span className={styles.rowTop}>
          <div className={styles.trackTitle}>
            {specialTrack
              ? num + " - " + getDisplayNameForArtist(track.artist)
              : track.title}
          </div>
          {specialTrack !== true ? (
            <div className={styles.rowIcon}>
              <Popover
                content={
                  <Menu className={styles.playMenu}>
                    <MenuItem
                      icon={<Icon icon={IconNames.MUSIC} />}
                      text="Play Song"
                      onClick={this.handleClickTrack}
                    />
                  </Menu>
                }
                position={Position.BOTTOM_RIGHT}
                minimal={true}
                className={Classes.DARK}
              >
                <Icon icon={IconNames.MORE} iconSize={10} />
              </Popover>
            </div>
          ) : (
            <div className={styles.rowIcon}>
              <Icon icon={IconNames.HEART} iconSize={10} />
            </div>
          )}
        </span>
        <span className={styles.rowButton}>
          {specialTrack !== true && (
            <div className={styles.trackArtist}>
              {getDisplayNameForArtist(track.artist)}
            </div>
          )}
        </span>
      </div>
    );
  }

  private handleClickTrack = () => {
    window.open(this.props.track.link, "_blank");
  };
}

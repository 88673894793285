import * as React from "react";
import styles from "../../styles/scottify/Controls.module.scss";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { Icon, ProgressBar } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Playlist, RIVAL_2019_PLAYLIST } from "../data/ScottifyData";



export const CURRENT_SONG_TITLE = "Scott Secret Santa 2020";
export  const CURRENT_SONG_ARTIST = "by Tots";
const COUNTDOWN_BEGIN = moment("2020-12-1", "YYYY-MM-DD");
const COUNTDOWN_END = moment("2020-12-25", "YYYY-MM-DD");
const COUNTDOWN_TOTAL_DAYS = moment.duration(COUNTDOWN_END.diff(COUNTDOWN_BEGIN)).asDays();

function getCountdownDaysCompleted(): number {
    const today = moment();
    return parseInt(moment
    .duration(today.diff(COUNTDOWN_BEGIN))
    .asDays()
    .toFixed(0));
}

function getCountdownProgress(): number {
    const daysPassed = getCountdownDaysCompleted();
    const progressVal = daysPassed / COUNTDOWN_TOTAL_DAYS;
    return progressVal;
}


export default class Controls extends React.Component<{}> {

  render() {
    if (isMobile) {
      return (
        <React.Fragment>
          {this.renderControlsMobile()}
          {this.renderMobileBottomBar()}
        </React.Fragment>
      )
    }
    return this.renderControlsWeb();
  }

  private handleClickPlay = () => {
    window.open(
      "https://open.spotify.com/playlist/3zcvZEa4j9Wve0pUWn3zxe",
      "_blank"
    );
  };

  private selectPlaylist = (playlist: Playlist) => {
    this.setState({ selectedPlaylist: playlist });
  };

  private renderMobileBottomBar = () => {
    return (
    <div className={styles.mobileBottomBar}>
      <div className={styles.bottomButton}>
        <Icon className={styles.buttonIcon} icon={IconNames.HOME}/>
        <div>Home</div>
      </div>
      <div className={classNames(styles.bottomButton, styles.disabled)}>
        <Icon className={styles.buttonIcon}  icon={IconNames.SEARCH}/>
        <div>Rules</div>
      </div>
      <div className={classNames(styles.bottomButton, styles.disabled)}>
        <Icon className={styles.buttonIcon}  icon={IconNames.BOOK}/>
        <div>Library</div>
      </div>
    </div>
    );
  }


  private renderControlsMobile = () => {
    const progressVal = getCountdownProgress();
    return (
        <div className={styles.controlsBarMobile}>
          <div className={styles.progressBar}>
            <ProgressBar value={progressVal} animate={false} stripes={false} />
          </div>
          <div className={styles.mainControls}>
            <Icon
              className={classNames(styles.controlIcon, styles.heart)}
              icon={IconNames.HEART}
              iconSize={14}
              onClick={() => this.selectPlaylist(RIVAL_2019_PLAYLIST)}
            />
            <div className={styles.title}>
              {CURRENT_SONG_TITLE}
              <Icon
                className={styles.titleIcon}
                icon={IconNames.DOT}
                iconSize={8}
                onClick={this.handleClickPlay}
              />
              <span className={styles.mainTrackArtist}>{CURRENT_SONG_ARTIST}</span>
            </div>
            <Icon
              className={classNames(styles.controlIcon, styles.play)}
              icon={IconNames.PLAY}
              iconSize={28}
              onClick={this.handleClickPlay}
            />
          </div>
        </div>
    );
  };

  private renderControlsWeb = () => {
    const daysPassed = getCountdownDaysCompleted();
    const progressVal = getCountdownProgress();

    return (
      <React.Fragment>
        <div className={styles.controlsBar}>
          <Icon
            className={styles.controlIcon}
            icon={IconNames.RANDOM}
            iconSize={12}
          />
          <Icon className={styles.controlIcon} icon={IconNames.STEP_BACKWARD} />
          <Icon
            className={classNames(styles.controlIcon, styles.play)}
            icon={IconNames.PLAY}
            iconSize={18}
            onClick={this.handleClickPlay}
          />
          <Icon className={styles.controlIcon} icon={IconNames.STEP_FORWARD} />
          <Icon
            className={styles.controlIcon}
            icon={IconNames.REFRESH}
            iconSize={12}
          />
        </div>
        <div className={styles.progressBar}>
          <span className={styles.durationMarker}>{daysPassed}</span>
          <ProgressBar value={progressVal} animate={false} stripes={false} />
          <span className={styles.durationMarker}>{COUNTDOWN_TOTAL_DAYS}</span>
        </div>
        <div className={styles.mainTrackTitle}>
          {CURRENT_SONG_TITLE}
          <span className={styles.mainTrackArtist}>{CURRENT_SONG_ARTIST}</span>
        </div>
      </React.Fragment>
    );
  };


}

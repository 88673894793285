import * as React from "react";
import { useSelector } from "react-redux";
import { useMobileMediaQuery } from "../state/ResponsivePage";
import styles from "../styles/buzzfeed/BuzzFeedHome.module.scss";
import { BuzzFeedHeaderBar } from "./components/BuzzFeedHeaderBar";
import { BuzzFeedMobileHeaderBar } from "./components/BuzzFeedMobileHeaderBar";
import { BuzzFeedQuiz } from "./components/BuzzFeedQuiz";
import { ContentPreviewBody } from "./components/ContentPreviewBody";
import { RulesBody } from "./components/RulesBody";
import { BuzzFeedNavTab } from "./data/BuzzFeedData";
import { getBuzzFeedSelectedTab } from "./state/BuzzFeedSelectors";

export const BuzzFeedHome = React.memo(function BuzzFeedHomeInternal() {
  const selectedTab = useSelector(getBuzzFeedSelectedTab);
  const isMobile = useMobileMediaQuery();

  return (
    <div className={styles.buzzFeedHome}>
      {isMobile ? <BuzzFeedMobileHeaderBar /> : <BuzzFeedHeaderBar />}
      <div className={styles.body}>
        {selectedTab === BuzzFeedNavTab.HOME && <ContentPreviewBody />}
        {selectedTab === BuzzFeedNavTab.QUIZ && <BuzzFeedQuiz />}
        {selectedTab === BuzzFeedNavTab.RULES && <RulesBody />}
      </div>
    </div>
  );
});

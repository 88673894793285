import * as React from "react";
import styles from "../../styles/scottify/PlaylistAlbumCover.module.scss";
import { Playlist } from "../data/ScottifyData";

declare namespace PlaylistAlbumCover {
  export interface Props {
    playlist: Playlist;
  }
}

// eslint-disable-next-line no-redeclare
export default class PlaylistAlbumCover extends React.Component<PlaylistAlbumCover.Props> {
  render() {
    const { playlist } = this.props;

    return (
      <div
        className={styles.playlistAlbumCover}
      >
        <div
          className={styles.albumCoverImage}
          style={{
            backgroundImage: `url(/img/scottify/playlist-covers/${
              playlist.image
            })`
          }}
        />
      </div>
    );
  }
}

import classNames from "classnames";
import * as React from "react";
import { PELOTON_RULES } from "../../peloton/data/Rules";
import rulesStyles from "../../styles/alone/RulesBody.module.scss";
import styles from "../../styles/buzzfeed/RulesBody.module.scss";

export const RulesBody = React.memo(function RulesBodyInternal() {
  return (
    <div className={classNames(styles.rulesBody)}>
      <div className={rulesStyles.rulesBody}>
        {PELOTON_RULES.map((rule) => (
          <div className={rulesStyles.ruleContainer} key={rule.rule}>
            <div className={rulesStyles.ruleImageContainer}>
              <img className={rulesStyles.ruleImage} src={rule.image} alt="" />
            </div>
            <div className={rulesStyles.ruleText}>
              {rule.heading && (
                <div className={rulesStyles.ruleHeader}>{rule.heading}</div>
              )}
              <div className={rulesStyles.ruleDescription}>{rule.rule}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

import * as React from "react";
import { useDispatch } from "react-redux";
import styles from "../../styles/alone/AloneHome.module.scss";
import { AloneNavTab } from "../data/AloneData";
import { AloneActions } from "../state/AloneActions";

export const AloneHeaderBar = React.memo(function AloneHeaderBarInternal() {
  const dispatch = useDispatch();

  return (
    <div className={styles.header}>
      <div className={styles.headerLogo}>
        <img
          className={styles.headerLogoImage}
          src="/img/alone/history_channel_logo.svg"
          alt=""
          onClick={() => dispatch(AloneActions.selectTab(AloneNavTab.HOME))}
        />
      </div>
      <div className={styles.navContainer}>
        <div
          className={styles.navItem}
          onClick={() => dispatch(AloneActions.selectTab(AloneNavTab.HOME))}
        >
          Shows
        </div>
        <div
          className={styles.navItem}
          onClick={() => dispatch(AloneActions.selectTab(AloneNavTab.RULES))}
        >
          Rules
        </div>
      </div>
    </div>
  );
});

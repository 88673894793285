import classNames from "classnames";
import { map } from "lodash";
import * as React from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { GlobalState } from "../state/globalState";
import pelotonHomeStyles from "../styles/peloton/PelotonHome.module.scss";
import pageStyles from "../styles/shared/Page.module.scss";
import InstructorList from "./components/InstructorList";
import InstructorView from "./components/InstructorView";
import PelotonHeaderBar from "./components/PelotonHeaderBar";
import PelotonRules from "./components/PelotonRules";
import {
  INSTRUCTORS,
  Instructors,
  InstructorType,
  PelotonNavTab,
} from "./data/PelotonData";
import { PelotonActions } from "./redux/PelotonActions";

declare namespace PelotonHome {
  export interface StateProps {
    selectedTab: PelotonNavTab;
    selectedInstructor: keyof typeof Instructors | undefined;
    selectedClassType: InstructorType;
  }

  export interface ConnectedActions {
    selectTab: (tab: PelotonNavTab) => void;
    selectInstructor: (
      instructor: keyof typeof Instructors | undefined
    ) => void;
    selectClassType: (classType: InstructorType) => void;
  }

  export type Props = StateProps & ConnectedActions;
}

// eslint-disable-next-line no-redeclare
class PelotonHomeInternal extends React.PureComponent<PelotonHome.Props, {}> {
  private renderHomeBody = () => {
    const { selectedClassType, selectClassType } = this.props;

    return (
      <div className={pelotonHomeStyles.body}>
        <div
          className={pelotonHomeStyles.headlineImageContainer}
          style={{
            backgroundImage:
              "linear-gradient(to top, rgba(18, 18, 18, 0.6), rgba(18, 18, 18, 0.5), rgba(18, 18, 18, 0.4)), url(/img/peloton/FamilyBlackWhite.png)",
          }}
        >
          Peloton Instructors
        </div>
        <div className={pelotonHomeStyles.categories}>
          {map(InstructorType, (type) => (
            <div
              className={classNames(pelotonHomeStyles.category, {
                [pelotonHomeStyles.selected]: type === selectedClassType,
              })}
              onClick={() => selectClassType(type)}
              key={type}
            >
              {type}
            </div>
          ))}
        </div>
        <InstructorList
          onClick={this.props.selectInstructor}
          classType={selectedClassType}
        />
      </div>
    );
  };

  private renderRulesBody = () => {
    return (
      <div className={pelotonHomeStyles.body}>
        <PelotonRules />
      </div>
    );
  };

  private renderInstructorProfile = () => {
    const { selectedInstructor } = this.props;
    if (selectedInstructor == null) {
      return;
    }

    const instructor = INSTRUCTORS.find(
      (instructor) => instructor.key === selectedInstructor
    );
    if (instructor == null) {
      return;
    }

    return (
      <div className={pelotonHomeStyles.body}>
        <InstructorView instructor={instructor} />
      </div>
    );
  };

  render() {
    const { selectedTab, selectedInstructor, selectTab } = this.props;

    return (
      <div
        className={classNames(pageStyles.page, pelotonHomeStyles.pelotonHome, {
          [pelotonHomeStyles.mobile]: isMobile,
        })}
      >
        <PelotonHeaderBar onClick={selectTab} />
        {selectedTab === PelotonNavTab.HOME &&
          selectedInstructor == null &&
          this.renderHomeBody()}
        {selectedTab === PelotonNavTab.HOME &&
          selectedInstructor != null &&
          this.renderInstructorProfile()}
        {selectedTab === PelotonNavTab.RULES && this.renderRulesBody()}
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState): PelotonHome.StateProps => {
  return {
    selectedTab: state.pelotonState.selectedTab,
    selectedInstructor: state.pelotonState.selectedInstuctor,
    selectedClassType: state.pelotonState.selectedClassType,
  };
};

const mapDispatchToProps = (dispatch: any): PelotonHome.ConnectedActions => ({
  selectTab: (tab: PelotonNavTab) => {
    dispatch(PelotonActions.selectTab(tab));
  },
  selectInstructor: (instructor: keyof typeof Instructors | undefined) => {
    dispatch(PelotonActions.selectInstructor(instructor));
  },
  selectClassType: (classType: InstructorType) => {
    dispatch(PelotonActions.selectClassType(classType));
  },
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PelotonHome = connect(
  mapStateToProps,
  mapDispatchToProps
)(PelotonHomeInternal);

import classNames from "classnames";
import * as React from "react";
import styles from "../../styles/buzzfeed/QuestionContainerText.module.scss";
import { BaseQuestion } from "../data/BuzzFeedData";

interface QuestionContainerTextProps {
  question: BaseQuestion;
  selectAnswer: (question: number, answer: number) => void;
  questionNumber: number;
  selectedAnswer: number | undefined;
}

export const QuestionContainerText = React.memo(
  function QuestionContainerTextInternal({
    question,
    selectAnswer,
    selectedAnswer,
    questionNumber,
  }: QuestionContainerTextProps) {
    return (
      <div className={styles.questionContainerText}>
        <div className={styles.question}>{question.text}</div>
        <div
          className={classNames(styles.answerBlock, {
            [styles.imageBlock]: question.answers[0].image != null,
          })}
        >
          {question.answers.map((answer, index) => (
            <div
              className={classNames(styles.answer, {
                [styles.selected]: selectedAnswer === index,
                [styles.imageAnswer]: answer.image != null,
              })}
              key={index}
              onClick={() => selectAnswer(questionNumber, index)}
            >
              {answer.image != null ? (
                <div className={styles.imageContainer}>
                  <div
                    className={styles.image}
                    style={{ backgroundImage: "url(" + answer.image + ")" }}
                  />
                </div>
              ) : (
                answer.text
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
);

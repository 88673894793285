export enum PelotonNavTab {
    HOME = "HOME",
    RULES = "RULES"
}

export enum Instructors {
    JAN = "JAN",
    RIDG = "RIDG",
    ANNA = "ANNA",
    YAN = "YAN",
    SARA = "SARA",
    JORDAN = "JORDAN",
    PETER = "PETER",
    TOTS = "TOTS",
    GRIFFIN = "GRIFFIN",
    NUTMEG = "NUTMEG"
}

export enum InstructorType {
    ALL = "ALL",
    BIKE = "BIKE",
    TREAD = "TREAD",
    YOGA = "YOGA",
}

export interface Instructor {
    key: keyof typeof Instructors,
    name: string;
    type: InstructorType[];
    tileImgUrl?: string;
    bioImgUrl?: string;
    quoteImgUrl?: string;
    quoteSignature?: string;
    quote?: string;
    bio?: string;
}

export const INSTRUCTORS: Instructor[] = [
    { 
        key: Instructors.JAN, 
        name: "Jan Englund", 
        type: [InstructorType.TREAD],
        tileImgUrl: "/img/peloton/instructor-tiles/InstructorTile_Jan.png",
        quote: "This is a photo of the papercut on my index finger suffered during my signing of 100 medical charts today. The exercise done by my fingers on my right hand was extreme.",
        quoteImgUrl: "/img/peloton/quote-images/QuoteJan.jpg",
        quoteSignature: "JAN/MOM",
        bioImgUrl: "/img/peloton/InstructorJan.jpg",
        bio: `Looking for a great workout? Keep looking! Similar to her stint as an Uber driver, Jan only signed up to be a Peloton instructor as an accident when downloading the app. Part workout and part stopping to look at birds, Jan will have you coasting just below your resting heart rate. You may find yourself surging ahead as Jan stops abruptly to take a picture. You’ll wonder “should I keep going or should I wait?” Who knows! 
        \nSet to a soundtrack of utter silence and with a complete lack of encouragement, Jan may not push you, but she will push your buttons.`
     },
    { 
        key: Instructors.RIDG, 
        name: "Ridg Scott", 
        type: [InstructorType.BIKE],
        tileImgUrl: "/img/peloton/instructor-tiles/InstructorTile_Ridg.png",
        quote: "We call those chapeaus.",
        quoteImgUrl: "/img/peloton/quote-images/QuoteRidg.jpg",
        bioImgUrl: "/img/peloton/instructor-main/MainRidg.jpg",
        bio: `From a young age, this instructor got most of his exercise from jazz band and networking. From that unique background, he joins us as one of Peloton's only emeritus instructors. He’ll put out so much content you’ll think to yourself “Wait - I thought this guy was retired??“
        \nGet ready to press firmly down on the brake as Ridg frequently stops to engage in discussion surrounding lift, dehydrons, and autonomous quadcopter flight. Empty that water bottle – you won’t need it for a ride with Ridg. This workout will be two parts gin and one part tonic. Is this happy hour or an exercise class? Definitely yes! `
    },
    { 
        key: Instructors.ANNA, 
        name: "Anna Scott", 
        type: [InstructorType.BIKE],
        tileImgUrl: "/img/peloton/instructor-tiles/InstructorTile_Anna.png",
        quote: "What about exercising my 1st amendment rights?",
        quoteImgUrl: "/img/peloton/quote-images/QuoteAnna.jpg",
        bioImgUrl: "/img/peloton/instructor-main/MainAnna.jpg",
        bio: `Meet Dr. Anna, our most overqualified Peloton instructor. From her background as a non-medical doctor and coal lobbyist, this instructor shares her remarkable passion for exercise with all who tune in. She’s bringing a whole new energy to Peloton - and it’s definitely not renewable! Pipe in some natural gas (the cleanest and safest of all energy sources!) and power-up that bike with Anna.
        \nForgot your workout clothes or don’t own any? Not a problem. Work boots and hardhats are just fine for this type of exercise. This instructor hosts new classes very rarely, but don’t worry - they’re worth the wait.
        \nIgnore your self-doubt just as Anna ignored her morals and let Anna help you achieve your fitness goals! `
    },
    { 
        key: Instructors.YAN, 
        name: "Yan Azdoud", 
        type: [InstructorType.TREAD], 
        tileImgUrl: "/img/peloton/instructor-tiles/InstructorTile_Yan.png",
        quote: "We like to do what we call a “self service” type of party.",
        quoteImgUrl: "/img/peloton/quote-images/QuoteYan.jpg",
        bioImgUrl: "/img/peloton/instructor-main/MainYan.jpg",
        bio: `Our least enthusiastic instructor, Yan joins the Peloton team mostly out of peer pressure. From his background as a Moroccan dancer and tattoo artist, Yan brings creativity, self-assuredness, and an albuterol inhaler into every session. In his vaguely exercise-themed classes, Yan will teach you about things even he doesn’t know about. 
        \nWork smarter not harder with Yan as he’ll teach you every shortcut in the book. Trying to break that output record? Watch as Yan hooks up his Dewalt power drill to the pedals and sits back to relax. These classes are perfect for those looking to get into e-biking shape! 
        \nHe may be our only French instructor, but this will not be the Tour de France you were expecting!`
    },
    { 
        key: Instructors.SARA, 
        name: "Sara Scott", 
        type: [InstructorType.BIKE],
        tileImgUrl: "/img/peloton/instructor-tiles/InstructorTile_Sara.png",
        quote: `[8:54 AM, 11/13/2021] Sara Scott: It’s time for a workout!!!!!
        \n[9:19 AM, 11/13/2021] Sara Scott: @Yan Azdoud 9th floor!! See you soon 😘`,
        bioImgUrl: "/img/peloton/instructor-main/MainSara.jpg",
        quoteImgUrl: "/img/peloton/quote-images/QuoteSara.jpg",
        bio: `Help Sara power an African village from afar! After expecting an extended instructing outage due to a year-long commitment with Médecins Sans Équipement d'Exercice (MSEdE), Sara surprise-joins us from the banks of Lake Malawi. Connected to the village's generator and sporting a mild case of Yellow Fever, Sara proves that there is rarely a legitimate excuse to NOT exercise. 
        \nAfter taking a class with Sara, all users involuntarily activate the “Treat Me Like a Family Member” function--which includes daily push notifications reminding you to exercise and endearing catch phrases such as “stretching isn’t exercise, Anna” or “Don’t you want to live long enough to see Peter’s children?”`
    },
    { 
        key: Instructors.JORDAN, 
        name: "Jordan O’Neill", 
        type: [InstructorType.TREAD],
        tileImgUrl: "/img/peloton/instructor-tiles/InstructorTile_Jordan.png",
        quote: "What’s going on with this hat situation?",
        quoteImgUrl: "/img/peloton/quote-images/QuoteJordan.jpg",
        bioImgUrl: "/img/peloton/instructor-main/MainJordan.png",
        bio: `Armed with short shorts and a middling work ethic, this instructor will take you for a ride - after he takes the dog to the park. And if he isn’t disc golfing. And as long as he isn’t too sleepy. Set to a soundtrack of various youtube videos, you’ll come away from this class sweaty, confident, and saying “Wow that guy has a lot of free time.” This early adopter and temporary bachelor will take you through ups and downs on the bike as well as his life, as he navigates empty nesting, finding the right candle scent, and single-parenting a sourdough starter. 
        \nPick your favorite running shoes - better yet, buy new ones! - and hop on the bike with Jordan!`,
    },
    { 
        key: Instructors.PETER, 
        name: "Peter Scott", 
        type: [InstructorType.BIKE],
        tileImgUrl: "/img/peloton/instructor-tiles/InstructorTile_Peter.png",
        quote: "The blanket without the woodpecker is a 7/10, maybe an 8/10. With the woodpecker, it’s a 13/10.",
        quoteImgUrl: "/img/peloton/quote-images/QuotePeter.jpg",
        bioImgUrl: "/img/peloton/instructor-main/MainPeter.jpg",
        bio: `Our youngest and least accomplished instructor, Peter, joins Peloton from our newest and only mobile studio based out of REDACTED. His unique and shocking background in REDACTED helped make him feel at home in the mobile Peloton studio. His best qualities are REDACTED, but don’t be fooled - he also has his flaws! These include living in a van, flaunting his newfound wealth, having a tiny mouth, and hosting overly ambitious dinner parties, to name a few. We could keep going, but you can find out for yourself in these sweat-drenched sessions on the bike!
        \nAs our only solo instructor, Peter needs you as much as you need him! Please do turn on your video during this class, as again, Peter is extremely lonely.`,
    },
    { 
        key: Instructors.TOTS, 
        name: "Tots", 
        type: [InstructorType.YOGA], 
        tileImgUrl: "/img/peloton/instructor-tiles/InstructorTile_Tots.png",
        quote: "Yeah, it’s not my cookies Carl.",
        quoteImgUrl: "/img/peloton/quote-images/QuoteTots.jpg",
        bioImgUrl:  "/img/peloton/instructor-main/MainTots.jpg",
        bio: `Meet our substitute instructor - Tots! Flexible inside and out, Tots loves yoga and being open-minded. When she was told she was too flexible to be a professional contortionist, she turned to computer programming as a fallback. When she isn’t online dating, collecting wooden pallets, or moving out without saying goodbye, Tots joins us part-time to lead casual 7 minute per mile jogs and patented “hanging on the pull-up bar” workouts. The Peloton-Scotts team is lucky to have her as an honorary instructor! (or at least we were for the first few months, then she just kind of moved into the studio and hasn’t left...)
        \nGrab a $12 latte (who cares that you just spent $100 on a fancy new espresso machine) and tune in bright and early for a workout with Tots!`,
    },
    { 
        key: Instructors.GRIFFIN, 
        name: "Griffin", 
        type: [InstructorType.YOGA], 
        tileImgUrl: "/img/peloton/instructor-tiles/InstructorTile_Griffin.png",
        quote: "Woof.",
        quoteImgUrl: "/img/peloton/quote-images/QuoteGriffin.jpg",
        bioImgUrl:  "/img/peloton/instructor-main/MainGriffin.jpg",
        bio: "Our original and founding canine instructor, Griffin, is 25% Chow Chow, 75% supermutt, and 100% purebred athlete. For anyone looking to get in perfect fetch shape, or just wanting to get ready for a nap, grab a kong and log in at exactly 6:03am. No need to set an alarm, Griffin will make sure you are awake!"
    },
    { 
        key: Instructors.NUTMEG, 
        name: "Nutmeg", 
        type: [InstructorType.YOGA], 
        tileImgUrl: "/img/peloton/instructor-tiles/InstructorTile_Nutmeg.png",
        quote: "Woof.",
        quoteImgUrl: "/img/peloton/quote-images/QuoteNutmeg.jpg",
        bioImgUrl:  "/img/peloton/instructor-main/MainNutmeg.jpg",
        bio: "Our newest canine instructor and a crowd favorite, Nutmeg, comes from an Amish and equine background. Using instinct and a lack of discipline, Nutmeg leads us through powerful swimming workouts that will make you feel like you just chased a duck for hours!",
    },
]


import { Rules } from "../../scottflix/data/ScotflixData";

export const SCOTTIFY_RULES: Rules[] = [
    {
      rule:
        "Each person will be assigned ONLY ONE other person in the family to whom they will give a present. Steps will be taken to ensure you are not assigned the same person as last year.",
      image: "/img/scottflix/rules-icons/agent.png"
    },
    {
      rule:
        "Suggested present value is around $50. Maximum spend is $100. Homemade gifts are HIGHLY encouraged (STILL lookin' at you Dad).",
      image: "/img/scottflix/rules-icons/money.png"
    },
    {
      heading: "Children",
      rule:
        "If you would like, you have the option to give 1 small gift (of less than $5) or an inexpensive homemade gift to non assigned secret santa recipients. MUST be small or homemade (ie soap, or food).",
      image: "/img/scottflix/rules-icons/gift.png"
    },
    {
      heading: "Parents",
      rule:
        "You are allowed to stuff stockings with small gifts. You ARE NOT allowed to otherwise break the rules and give other gifts to non assigned secret santa recipients (STILL lookin' at you Mom). The punishment for breaking this rule is death.",
      image: "/img/scottflix/rules-icons/skull.png"
    },
    {
      rule:
        "There will be a $50 pay to play fee. This money will be split and donated to organizations decided upon by the top two finishers of the secret santa gift exchange (yes, there will be a winner).",
      image: "/img/scottflix/rules-icons/trophy.png"
    },
    {
      rule:
        "You will receive your secret santa assignment in a separate email. Gifts will be exchanged on [sometime around Christmas]",
      image: "/img/scottflix/rules-icons/email.png"
    }
  ];
import { defineAction } from "redoodle";
import { BuzzFeedNavTab, Contestants } from "../data/BuzzFeedData";

export const PREFIX = "@scott-secret-santa//buzzfeed";

export const selectTab = defineAction(`${PREFIX}//selectTab`)<BuzzFeedNavTab>();

export const selectContestant = defineAction(`${PREFIX}//selectContestant`)<
  keyof typeof Contestants | undefined
>();

export const BuzzFeedActions = {
  selectTab,
  selectContestant,
};

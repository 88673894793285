import { TypedAction } from "redoodle";
import { Instructors, InstructorType, PelotonNavTab } from "../data/PelotonData";

export const PREFIX = "@scott-secret-santa//peloton";

export const selectTab = TypedAction.define(`${PREFIX}//selectTab`)<
PelotonNavTab
>();

export const selectInstructor = TypedAction.define(`${PREFIX}//selectInstructor`)<
keyof typeof Instructors | undefined
>();

export const selectClassType = TypedAction.define(`${PREFIX}//selectClassType`)<
InstructorType
>();

export const PelotonActions = {
    selectTab,
    selectInstructor,
    selectClassType
}
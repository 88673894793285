import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import styles from "../../styles/alone/ShowPreviewBody.module.scss";

interface ShowPreviewProps {
  tag: string;
  title: string;
  subtitle: string;
  action?: string;
  image: string;
  selectShow?: () => void;
}

export const ShowPreview = React.memo(function ShowPreviewInternal({
  tag,
  title,
  subtitle,
  action,
  image,
  selectShow,
}: ShowPreviewProps) {
  return (
    <div className={styles.previewContainer} onClick={selectShow}>
      <div className={styles.previewMetadata}>
        <div className={styles.tags}>
          <div className={styles.tag}>{tag}</div>
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
        {action != null && (
          <div className={styles.action}>
            <Icon
              className={styles.actionIcon}
              icon={IconNames.CHEVRON_RIGHT}
            />
            {action}
          </div>
        )}
      </div>
      <div
        className={styles.showImage}
        style={{ backgroundImage: "url(" + image + ")" }}
      />
    </div>
  );
});

import { setWith, TypedReducer } from "redoodle";
import { AloneNavTab, Contestants } from "../data/AloneData";
import { AloneActions } from "./AloneActions";
import { AloneState, getInitialAloneState } from "./AloneState";

export const aloneReducer = TypedReducer.builder<AloneState>()
  .withHandler(
    AloneActions.selectTab.TYPE,
    (state: AloneState, selectedTab: AloneNavTab) => {
      return setWith(state, {
        selectedTab: selectedTab,
        selectedContestant: undefined,
      });
    }
  )
  .withHandler(
    AloneActions.selectContestant.TYPE,
    (
      state: AloneState,
      selectedContestant: keyof typeof Contestants | undefined
    ) => {
      return setWith(state, {
        selectedContestant,
        selectedTab:
          selectedContestant == null
            ? AloneNavTab.CONTESTANTS
            : AloneNavTab.CONTESTANT_BIO,
      });
    }
  )
  .withDefaultHandler((_state) => getInitialAloneState())
  .build();

import { BuzzFeedNavTab, Contestants } from "../data/BuzzFeedData";

export interface BuzzFeedState {
  selectedTab: BuzzFeedNavTab;
  selectedContestant: keyof typeof Contestants | undefined;
}

export const INITIAL_BUZZFEED_STATE: BuzzFeedState = {
  selectedTab: BuzzFeedNavTab.HOME,
  selectedContestant: undefined,
};

export const getInitialBuzzFeedState = (): BuzzFeedState =>
  INITIAL_BUZZFEED_STATE;

import * as React from "react";
import { useSelector } from "react-redux";
import { useMobileMediaQuery } from "../state/ResponsivePage";
import styles from "../styles/alone/AloneHome.module.scss";
import { AloneHeaderBar } from "./components/AloneHeaderBar";
import { AloneMobileHeaderBar } from "./components/AloneMobileHeaderBar";
import { ContestantBioBody } from "./components/ContestantBioBody";
import { ContestantsBody } from "./components/ContestantsBody";
import { RulesBody } from "./components/RulesBody";
import { ShowHeaderBar } from "./components/ShowHeaderBar";
import { ShowPreviewBody } from "./components/ShowPreviewBody";
import { AloneNavTab } from "./data/AloneData";
import { getAloneSelectedTab } from "./state/AloneSelectors";

export const AloneHome = React.memo(function AloneHomeInternal() {
  const selectedTab = useSelector(getAloneSelectedTab);
  const isMobile = useMobileMediaQuery();

  return (
    <div className={styles.aloneHome}>
      {isMobile ? <AloneMobileHeaderBar /> : <AloneHeaderBar />}
      {selectedTab !== AloneNavTab.HOME &&
        selectedTab !== AloneNavTab.RULES && <ShowHeaderBar />}
      {selectedTab === AloneNavTab.HOME && <ShowPreviewBody />}
      {selectedTab === AloneNavTab.CONTESTANTS && <ContestantsBody />}
      {selectedTab === AloneNavTab.CONTESTANT_BIO && <ContestantBioBody />}
      {selectedTab === AloneNavTab.RULES && <RulesBody />}
    </div>
  );
});

import { ScottflixNavTab } from "../data/ScotflixData";

export interface ScottflixState {
    selectedTab: ScottflixNavTab;
}

export const INITIAL_SCOTTFLIX_STATE: ScottflixState = {
    selectedTab: ScottflixNavTab.HOME
};

export const getInitialState = (): ScottflixState => INITIAL_SCOTTFLIX_STATE;
import * as React from "react";
import classNames from "classnames";
import { IconNames } from "@blueprintjs/icons";
import { Button } from "@blueprintjs/core";
import ScottflixRules from "./ScottflixRules";
import styles from "../../styles/scottflix/ScottflixBody.module.scss";
import { ScottflixNavTab, Show, SHOWS } from "../data/ScotflixData";


declare namespace ScottflixBody {
  export interface Props {
    selectedTab: ScottflixNavTab;
  }

  export interface State {
    selectedShow: Show | undefined;
  }
}

// eslint-disable-next-line no-redeclare
export default class ScottflixBody extends React.Component<
ScottflixBody.Props,
ScottflixBody.State
> {
  state: ScottflixBody.State = {
    selectedShow: undefined
  };

  render() {
    const { selectedTab } = this.props;
    return (
      <div className={styles.scottflixBody}>
        {selectedTab === ScottflixNavTab.HOME ? this.renderHomeBody() : <ScottflixRules />}
      </div>
    );
  }

  public renderHomeBody() {
    const { selectedShow } = this.state;
    return (
      <React.Fragment>
        {selectedShow === undefined && (
          <div className={styles.headlinerContainer}>
            <div
              className={styles.headlinerBg}
              style={{
                backgroundImage: "url(/img/scottflix/familyphoto_loweropacity.jpg)"
              }}
            />
            <div className={styles.headlinerSubtitle}>
              <img
                className={styles.shield}
                src={"/img/scottflix/sss_shield_2019_red.png"}
                alt=""
              />
              <div className={styles.textContainer}>
                <div className={styles.subtitleText}>
                  The Third Bi-Annual Scott Family Gift-o-rama Fun Run to End
                  the Flu Secret Santa Gift Exchange
                </div>
                <div className={styles.subtitleIcons}>
                  <span className={styles.match}>98% Match</span>
                  <span className={styles.year}>DEC 2019</span>
                  <span className={styles.rating}>R</span>
                  <span className={styles.seasons}>3 Seasons</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.listContainer}>
          {selectedShow !== undefined ? (
            <div className={styles.topSpacer} />
          ) : (
            <div className={styles.sectionLabel}>Trending Now</div>
          )}
          {this.renderTrendingNow()}
        </div>
        {selectedShow && (
          <div className={styles.selectedShowContainer}>
            {this.renderShow(selectedShow)}
          </div>
        )}
      </React.Fragment>
    );
  }

  public renderTrendingNow = () => {
    const { selectedShow } = this.state;
    return (
      <div className={styles.trendingNow}>
        {SHOWS.map(show => {
          const previewImage = show.previewImg ? show.previewImg : show.img;
          return (
            <div className={styles.showPreview} key={show.title}>
              <img
                className={classNames(styles.showPreviewImage, {
                  [styles.selected]: selectedShow === show,
                  [styles.hoverEffect]: selectedShow === undefined,
                  [styles.nonSelectedShow]:
                    selectedShow !== undefined && selectedShow !== show
                })}
                src={previewImage}
                alt=""
                onClick={() => this.handleSelectShow(show)}
              />
              {selectedShow === show && <div className="arrow-down" />}
            </div>
          );
        })}
      </div>
    );
  };

  public renderShow(show: Show) {
    return (
      <div className={styles.selectedShow}>
        <div className={styles.infoContainer}>
          <Button
            icon={IconNames.CROSS}
            className={styles.closeButton}
            minimal={true}
            onClick={this.clearSelectedShow}
          />
          <div className={classNames(styles.infoTitle, show.className)}>
            {show.title}
          </div>
          <div className={styles.infoStar}>{"Starring " + show.star}</div>
          <div className={styles.infoDetails}>
            {show.details.map(details => (
              <div className={styles.detail} key={details.title + details.value}>
                <div className={styles.detailCategory}>{details.title + ":"}</div>
                <div className={styles.detailValue}>{details.value}</div>
              </div>
            ))}
          </div>
          <div className={styles.infoSummary}>
            {show.summary.map(summaryItem => (
              <React.Fragment>
                {summaryItem}
                <br />
                <br />
              </React.Fragment>
            ))}
          </div>
        </div>
        <div
          className={styles.selectedShowImage}
          style={{ backgroundImage: "url(" + show.img + ")" }}
        >
          <div className={styles.gradientCover} />
          <div className={classNames(styles.coverQuote, show.className)}>
            {`"` + show.slogan + `"`}
          </div>
        </div>
      </div>
    );
  }

  private clearSelectedShow = () => {
    this.setState({ selectedShow: undefined });
  };

  private handleSelectShow = (selectShow: Show) => {
    this.setState({
      selectedShow: selectShow
    });
  };
}

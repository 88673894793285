import * as React from "react";
import styles from "../../styles/buzzfeed/Badge.module.scss";

interface BadgeProps {
  image: string;
}

export const Badge = React.memo(function BadgeInternal({ image }: BadgeProps) {
  return (
    <div className={styles.badgeContainer}>
      <div
        className={styles.image}
        style={{ backgroundImage: "url(" + image + ")" }}
      />
    </div>
  );
});

export enum BuzzFeedNavTab {
  HOME = "HOME",
  RULES = "RULES",
  QUIZ = "QUIZ",
  CONTESTANT_BIO = "CONTESTANT_BIO",
}

export enum Contestants {
  JAN = "JAN",
  RIDG = "RIDG",
  ANNA = "ANNA",
  YAN = "YAN",
  SARA = "SARA",
  JORDAN = "JORDAN",
  PETER = "PETER",
  WINN = "WINN",
  TOTS = "TOTS",
  GRIFFIN = "GRIFFIN",
  NUTMEG = "NUTMEG",
}

export interface Contestant {
  key: keyof typeof Contestants;
  name: string;
  primaryImgUrl: string;
  bio: string | JSX.Element;
}

export const QUIZ_NAME =
  "This In-Depth Personality Test Will Reveal Which Scott You Are With 100% Accuracy";

export const CONTESTANTS: Contestant[] = [
  {
    key: Contestants.JAN,
    name: "Jan Englund",
    primaryImgUrl: "/img/buzzfeed/primary/Primary_Jan.jpeg",
    bio: `The busiest member of the Scott family, you juggle careers in medicine, baby photography, marketing for Aldo Leopold, and being a brand ambassador for BOTH Chirp and Shutterfly.
    \nWhen a global pandemic got in the way of your retirement plans, you made sure everyone knew exactly how inconvenient this was. And then you got a vaccine approved to keep things moving. All while keeping tabs on exactly how many wood ducks are in Lake Washington.
    \nYou probably haven’t finished reading this without checking an alert on your phone, you bold multitasker.
    \nAs the matriarch, you know that nothing will get done without you, and you want to make sure everyone else knows that too. You’re tough and smart and just want what is best for everyone, as long as that is birding.
    `,
  },
  {
    key: Contestants.RIDG,
    name: "Ridg Scott",
    primaryImgUrl: "/img/buzzfeed/primary/Primary_Ridg.jpeg",
    bio: `You are the life of the party and the “yes-man” of the family. There is no task you cannot [pay for someone to] accomplish. You don’t drive your car, you make your car drive you. When you find your yacht’s ladder to be inconvenient, you don’t replace it - you buy a whole new boat. While many use retirement as a time to “slow down” and “actually retire,” you are using yours as a springboard to stardom. Whoever said “you can’t teach an old dog new tricks” was not talking about you! This year alone, you have single handedly discovered airport hotels and innovated the best way to travel in comfort.
    \nDid you let a normal-sized garage get in the way of owning your oversized dream car? Absolutely not. Did you let slow construction get in the way of you living in your dream home? Well... yes. But not to worry - your unabashed optimism and passion for supervising always sees you through!
    \nAlso, Anna would like to let you know that she is impressed with your ability to speak French with her inlaws and she wanted me to put that in here.
    `,
  },
  {
    key: Contestants.ANNA,
    name: "Anna Scott",
    primaryImgUrl: "/img/buzzfeed/primary/Primary_Anna.jpeg",
    bio: `You are a natural leader. So natural, in fact, that your last company had to let you go after you started accumulating too much power. But not to worry - you were able to quickly pivot and start  either a deep sea mining company or a complicated money laundering situation. Despite your weak constitution, you manage to persevere.
    \nThe oldest of the Scott children, you are frequently blazing new trails. When the Scott Family inheritance was on the line, you bravely stepped up to produce an offspring and secure the goods. And now, you have not only a child, but a hat that says mama!
    \nYou thrive in situations in which you can demonstrate your superior knowledge and provide your correct and expert opinion, which, unsurprisingly, is most situations.
    `,
  },
  {
    key: Contestants.YAN,
    name: "Yan Azdoud",
    primaryImgUrl: "/img/buzzfeed/primary/Primary_Yan.jpeg",
    bio: `Can you be both a jack AND a master of all trades? You would say yes. As a new father, tattoo revolutionary, AND supporting character in a best selling self published semi autobiography, you are having a big year.  You made a questionable decision hitching your horse to this burning wagon of a country, but America is proud to call you hers at last!
    \nYou are a heavy sleeper, something that has never been as useful as it is this year now that you are a proud father! And a father who is sleeping soundly and proudly through the night at that.
    \nYou are a leader in the field of maximalist cooking, and an absolute savant at avoiding cleaning the kitchen.
    `,
  },
  {
    key: Contestants.SARA,
    name: "Sara Scott",
    primaryImgUrl: "/img/buzzfeed/primary/Primary_Sara.jpeg",
    bio: `The "Architect of Agendas," the "Choreographer of Cardio," the "List Luminary," and the "Mitochondria of the Scott Family Cell" are all nicknames you have heard over and over again. Your idea for a romantic evening involves planning a wedding for a couple that isn't yet engaged. You would plan a surprise party for yourself just to see if you could pull it off.
    \nAccidentally request a week off from work? No problem--plan a trip to Paris less than 20 days out. Easy. Find yourself with 30 hours between work shifts? Fly to Texas; problem solved.
    \nWhen you are not working or planning, you focus on your other passions--work, making stickers to hand out at work, and orchestrating elaborate murder mystery parties for former colleagues.
    \nYour only kryptonite is downtime. HA. Kidding, what's downtime? Where's my stand mixer?
    `,
  },
  {
    key: Contestants.JORDAN,
    name: "Jordan O'Neill",
    primaryImgUrl: "/img/buzzfeed/primary/Primary_Jordan.jpeg",
    bio: `You are frequently compared to vanilla ice cream - a flavor that you will go to your grave defending. In a family of overachievers, you try to stay somewhere around “middling.” In response to a mandated increase in your work productivity, you were forced to purchase and regularly utilize a video game console to maintain a net-neutral balance in output. You are frequently beseeching the family to take a vacation during which “we can all just relax,” but you know deep down this will never happen.
    \nYou don’t settle for mediocrity in all aspects of your life, however - something you demonstrated when you heroically returned opened toilet paper to Costco and demanded perfection.
    \nYour passions include discussing how much vacation you took in 2018, space heaters, and assessing the dog’s temperature by touching his nose. You are curious, excitable, and willing to buy anything with even the barest recommendation from a stranger online.
    \nWhat you lack in a functioning immune system, you make up for with other qualities. People regularly come to you for advice because you have basic internet skills and have at least slightly more than no patience (unlike the rest of the family).
    `,
  },
  {
    key: Contestants.PETER,
    name: "Peter Scott",
    primaryImgUrl: "/img/buzzfeed/primary/Primary_Peter.jpeg",
    bio: `The youngest in a family full of leadership potential, you’re just trying to get by in this women-run world.
    \nYou don’t let traditional expectations like “having a job” or “health insurance” stand in the way of your dream of having a running career in New York while living full time in Paris. And while everyone thought you were joking about opening a french restaurant in your New York apartment, you knew you were just biding your time. And now, you’ve either made a bold and admirable life choice or a huge mistake - maybe both!
    \nWhile some might call you irresponsible, you would say “non!” and then hop on google translate to finish formulating your response. You thought undertaking a remodel in Paris would be the quickest way to integrate into French culture.  And if you can’t yet communicate clearly in French, at least you can ace those grammar tests!
    `,
  },
  {
    key: Contestants.TOTS,
    name: "Tots",
    primaryImgUrl: "/img/buzzfeed/primary/Primary_Tots.jpeg",
    bio: `Although not technically part of the Scott family, your involvement with even the most mundane of Scott Family events has served to blur that distinction. Despite your lack of blood relation, you are still somehow Jan and Ridg’s favorite child, likely related to your ability to get through at least one conversation without starting a fight, your Delta status, and your consistent employment. These positive qualities allow others to overlook some less favorable characteristics, including your hoarding of eraser shavings and incapacity to grasp the concept of an “indoor voice.”
    \nYou are often described as high maintenance, grandfatherly, and weirdly obsessed with child baking shows. Your technical skills are surpassed only by your potential as an amateur parkour artist and your complete inability to multitask.
    `,
  },
  {
    key: Contestants.WINN,
    name: "Winn",
    primaryImgUrl: "/img/buzzfeed/primary/Primary_Winn.jpeg",
    bio: `You are the newest and most blameless member of the Scott Family! You are emotionally labile, always hungry, and pretty darn cute. And boy, do you know how to make an entrance. While only time will tell if you are wise beyond your years, we can say with certainty that you are large for your age.
    `,
  },
  {
    key: Contestants.GRIFFIN,
    name: "Griffin",
    primaryImgUrl: "/img/buzzfeed/primary/Primary_Griffin.jpeg",
    bio: `You are a “good boy” - until you see a deer. Your motto: Play hard, nap hard, eat hard.
    `,
  },
  {
    key: Contestants.NUTMEG,
    name: "Nutmeg",
    primaryImgUrl: "/img/buzzfeed/primary/Primary_Nutmeg.jpeg",
    bio: `Often referred to as “the cute one,” you’re a wild animal living the life of a lap dog. A full couch won’t stop you - you’ll squeeze right in the middle.
    `,
  },
];

export interface BaseQuestion {
  answers: BaseAnswer[];
  text: string;
}

export interface BaseAnswer {
  contestantTypes: Array<keyof typeof Contestants> | undefined;
  text: string;
  image?: string;
}

export const QUIZ_QUESTIONS: BaseQuestion[] = [
  {
    text: "Do you identify most as ...",
    answers: [
      {
        text: "a dog",
        contestantTypes: [Contestants.GRIFFIN, Contestants.NUTMEG],
      },
      { text: "a human infant", contestantTypes: [Contestants.WINN] },
      { text: "a human adult", contestantTypes: [] },
    ],
  },
  {
    text: "You arrive at a beautiful, peaceful beach. What is the first thing you do?",
    answers: [
      {
        text: "Get your binoculars out and start scouring the water - there’s gotta be a surf scoter out there",
        contestantTypes: [Contestants.JAN],
      },
      {
        text: "Start a sun tan timer and orient to true south",
        contestantTypes: [Contestants.JORDAN],
      },
      {
        text: "Trick question - you’re still in the car napping.",
        contestantTypes: [Contestants.RIDG],
      },
      {
        text: "Set up your tabata timer - this is a great time and place for a workout!",
        contestantTypes: [Contestants.SARA],
      },
      {
        text: "Start reading the New Yorker in a group of people and then get annoyed when said group tries to talk to you",
        contestantTypes: [Contestants.PETER],
      },
      {
        text: "Get your zinc oxide stick out and start applying - the whiter your skin is the better",
        contestantTypes: [Contestants.ANNA],
      },
      {
        text: "Pop in your headphones and listen to a book on tape",
        contestantTypes: [Contestants.YAN],
      },
    ],
  },
  {
    text: "Are you employed?",
    answers: [
      {
        text: "Yes",
        contestantTypes: [Contestants.JAN, Contestants.JORDAN, Contestants.YAN],
      },
      {
        text: "I'm in a two-on-two-off cycle",
        contestantTypes: [Contestants.SARA],
      },
      {
        text: "No",
        contestantTypes: [Contestants.PETER],
      },
      {
        text: "I’m technically retired but refuse to admit it",
        contestantTypes: [Contestants.RIDG],
      },
      {
        text: "Unclear",
        contestantTypes: [Contestants.ANNA],
      },
    ],
  },
  {
    text: "What is your idea of a workout?",
    answers: [
      {
        text: "Stretching",
        contestantTypes: [Contestants.ANNA],
      },
      {
        text: "I refuse to answer this question",
        contestantTypes: [Contestants.YAN],
      },
      {
        text: "Golf",
        contestantTypes: [Contestants.RIDG],
      },
      {
        text: "Paging through a really big stack of paperwork",
        contestantTypes: [Contestants.JAN],
      },
      {
        text: "Actual exercise",
        contestantTypes: [
          Contestants.SARA,
          Contestants.JORDAN,
          Contestants.PETER,
        ],
      },
    ],
  },
  {
    text: "What irks you the most?",
    answers: [
      {
        text: "Losing an argument/being told you are wrong/being questioned",
        contestantTypes: [Contestants.ANNA],
      },
      {
        text: "A messy kitchen",
        contestantTypes: [Contestants.JAN],
      },
      {
        text: "1-ply toilet paper",
        contestantTypes: [Contestants.JORDAN],
      },
      {
        text: "Being robbed of your naptime",
        contestantTypes: [Contestants.RIDG],
      },
      {
        text: "Store bought mayonnaise",
        contestantTypes: [Contestants.YAN],
      },
      {
        text: "Female dominated environments",
        contestantTypes: [Contestants.PETER],
      },
      {
        text: "The misidentification of recyclable and compostable items",
        contestantTypes: [Contestants.SARA],
      },
    ],
  },
  {
    text: "If someone were to write a biography about you, what would they title it?",
    answers: [
      {
        text: "Global Health in America’s Heartland",
        contestantTypes: [Contestants.SARA],
      },
      {
        text: "Time to Beurre-n",
        contestantTypes: [Contestants.PETER],
      },
      {
        text: "Blue Cruisin for a Bruisin",
        contestantTypes: [Contestants.RIDG],
      },
      {
        text: "Why I Love Birds More Than My Children",
        contestantTypes: [Contestants.JAN],
      },
      {
        text: "Gas-Lit - Oil Money and the Corruption of the Austin City Council",
        contestantTypes: [Contestants.ANNA],
      },
      {
        text: "Tattoo du Jour",
        contestantTypes: [Contestants.YAN],
      },
      {
        text: "Epic Failure",
        contestantTypes: [Contestants.JORDAN],
      },
    ],
  },
  {
    text: "What is your favorite thing to watch?",
    answers: [
      {
        text: "Obscure youtube channels about families living on boats",
        contestantTypes: [Contestants.ANNA],
      },
      {
        text: "Obscure youtube channels about single men living in vans",
        contestantTypes: [Contestants.PETER],
      },
      {
        text: "A slow paced crime drama taking place in rural English countryside",
        contestantTypes: [Contestants.JAN, Contestants.RIDG],
      },
      {
        text: "Anime",
        contestantTypes: [Contestants.YAN],
      },
      {
        text: "All of the above",
        contestantTypes: [Contestants.JORDAN],
      },
      {
        text: "Nothing",
        contestantTypes: [Contestants.SARA],
      },
    ],
  },
  {
    text: "What is your preferred method of transportation?",
    answers: [
      {
        text: "Airplane, but only if it’s first class",
        contestantTypes: [Contestants.RIDG],
      },
      {
        text: "Airplane, but I’m not paying for first class",
        contestantTypes: [Contestants.JAN],
      },
      {
        text: "Van",
        contestantTypes: [Contestants.PETER],
      },
      {
        text: "Train - the longer the better",
        contestantTypes: [Contestants.ANNA],
      },
      {
        text: "Bike",
        contestantTypes: [Contestants.SARA],
      },
      {
        text: "Ebike",
        contestantTypes: [Contestants.YAN],
      },
      {
        text: "Electric vehicle as soon as Sara will let me buy one",
        contestantTypes: [Contestants.JORDAN],
      },
    ],
  },
  {
    text: "Which of the following would you pick as your catchphrase?",
    answers: [
      {
        text: "When it’s gone, it’s gone",
        contestantTypes: [Contestants.JAN],
      },
      {
        text: "Rich or poor, it pays to have money",
        contestantTypes: [Contestants.RIDG],
      },
      {
        text: "What sauce are you planning to make with this?",
        contestantTypes: [Contestants.YAN],
      },
      {
        text: "I missed my flight...",
        contestantTypes: [Contestants.PETER],
      },
      {
        text: "I’m just the blue collar chef",
        contestantTypes: [Contestants.JORDAN],
      },
      {
        text: "Going over my to do list IS relaxing",
        contestantTypes: [Contestants.SARA],
      },
      {
        text: "I saw this on tiktok",
        contestantTypes: [Contestants.ANNA],
      },
    ],
  },
  {
    text: "Pick a hobby:",
    answers: [
      {
        text: "",
        contestantTypes: [Contestants.PETER],
        image: "/img/buzzfeed/quiz/hobby1.jpg",
      },
      {
        text: "",
        contestantTypes: [Contestants.JORDAN],
        image: "/img/buzzfeed/quiz/hobby2.jpg",
      },
      {
        text: "",
        contestantTypes: [Contestants.SARA],
        image: "/img/buzzfeed/quiz/hobby3.jpg",
      },
      {
        text: "",
        contestantTypes: [Contestants.JAN],
        image: "/img/buzzfeed/quiz/hobby4.jpg",
      },
      {
        text: "",
        contestantTypes: [Contestants.RIDG],
        image: "/img/buzzfeed/quiz/hobby5.jpg",
      },
      {
        text: "",
        contestantTypes: [Contestants.ANNA],
        image: "/img/buzzfeed/quiz/hobby6.jpg",
      },
      {
        text: "",
        contestantTypes: [Contestants.YAN],
        image: "/img/buzzfeed/quiz/hobby7.jpg",
      },
    ],
  },
  {
    text: "What is your perfect water bottle?",
    answers: [
      {
        text: "",
        contestantTypes: [Contestants.RIDG],
        image: "/img/buzzfeed/quiz/bottle1.jpg",
      },
      {
        text: "",
        contestantTypes: [
          Contestants.SARA,
          Contestants.JORDAN,
          Contestants.PETER,
        ],
        image: "/img/buzzfeed/quiz/bottle2.jpg",
      },
      {
        text: "",
        contestantTypes: [Contestants.JAN],
        image: "/img/buzzfeed/quiz/bottle3.png",
      },
      {
        text: "",
        contestantTypes: [Contestants.YAN],
        image: "/img/buzzfeed/quiz/bottle4.png",
      },
      {
        text: "",
        contestantTypes: [Contestants.ANNA],
        image: "/img/buzzfeed/quiz/bottle5.jpg",
      },
    ],
  },
  // {
  //   text: "",
  //   answers: [
  //     {
  //       text: "",
  //       contestantTypes: []
  //     },
  //     {
  //       text: "",
  //       contestantTypes: []
  //     },
  //           {
  //       text: "",
  //       contestantTypes: []
  //     },
  //     {
  //       text: "",
  //       contestantTypes: []
  //     },
  //           {
  //       text: "",
  //       contestantTypes: []
  //     },
  //     {
  //       text: "",
  //       contestantTypes: []
  //     },
  //   ],
  // },
];

export const DOG_QUIZ_QUESTIONS: BaseQuestion[] = [
  {
    text: "Do you identify most as ...",
    answers: [
      {
        text: "a dog",
        contestantTypes: [Contestants.GRIFFIN, Contestants.NUTMEG],
      },
      { text: "a human infant", contestantTypes: [Contestants.WINN] },
      { text: "a human adult", contestantTypes: [] },
    ],
  },
  {
    text: "Which would you rather chase?",
    answers: [
      {
        text: "",
        contestantTypes: [Contestants.NUTMEG],
        image: "/img/buzzfeed/quiz/chase1.jpg",
      },
      {
        text: "",
        contestantTypes: [Contestants.GRIFFIN],
        image: "/img/buzzfeed/quiz/chase2.jpg",
      },
    ],
  },
  {
    text: "What is your ideal track workout?",
    answers: [
      {
        text: "Sleeping in the shade",
        contestantTypes: [Contestants.GRIFFIN],
      },
      {
        text: "Running with the leader",
        contestantTypes: [Contestants.NUTMEG],
      },
    ],
  },
  {
    text: "What are you best at?",
    answers: [
      {
        text: "Fetch",
        contestantTypes: [Contestants.NUTMEG],
      },
      {
        text: "Finding the softest place to sleep",
        contestantTypes: [Contestants.GRIFFIN],
      },
    ],
  },
  {
    text: "What is your favorite meal of the day?",
    answers: [
      {
        text: "All of them",
        contestantTypes: [Contestants.GRIFFIN],
      },
      {
        text: "I only eat when my pack eats",
        contestantTypes: [Contestants.NUTMEG],
      },
    ],
  },
  // {
  //   text: "",
  //   answers: [
  //     {
  //       text: "",
  //       contestantTypes: []
  //     },
  //     {
  //       text: "",
  //       contestantTypes: []
  //     },
  //           {
  //       text: "",
  //       contestantTypes: []
  //     },
  //     {
  //       text: "",
  //       contestantTypes: []
  //     },
  //           {
  //       text: "",
  //       contestantTypes: []
  //     },
  //     {
  //       text: "",
  //       contestantTypes: []
  //     },
  //   ],
  // },
];

import * as React from "react";
import styles from "../../styles/scottify/AlbumCover.module.scss";

import classNames from "classnames";
import { Playlist } from "../data/ScottifyData";
import { isMobile } from "react-device-detect";

declare namespace AlbumCover {
  export interface Props {
    playlist: Playlist;
    includeFullTitle?: boolean;
    isSelected: boolean;
    onClick: (playlist: Playlist) => void;
  }
}

// eslint-disable-next-line no-redeclare
export default class AlbumCover extends React.Component<AlbumCover.Props> {
  render() {
    const { playlist, isSelected, onClick, includeFullTitle } = this.props;

    return (
      <div
        className={classNames(styles.albumCover, { [styles.isSelected]: isSelected, [styles.mobile]: isMobile })}
        onClick={() => onClick(playlist)}
      >
        <div
          className={styles.albumCoverImage}
          style={{
            backgroundImage: `url(/img/scottify/playlist-covers/${
              playlist.image
            })`
          }}
        />
        <div
          className={classNames(styles.albumCoverTitle, {
            [styles.longTitle]: playlist.title.length > 20,
            [styles.includeFullTitle]: includeFullTitle
          })}
        >
          {playlist.title}
        </div>
      </div>
    );
  }
}

import * as React from "react";
import styles from "../../styles/buzzfeed/ContentPreviewBody.module.scss";

interface PostPreviewProps {
  title: string;
  image: string;
}

export const PostPreview = React.memo(function ContentPreviewInternal({
  title,
  image,
}: PostPreviewProps) {
  return (
    <div className={styles.postPreviewContainer}>
      <div className={styles.imageWrapper}>
        <div
          className={styles.image}
          style={{ backgroundImage: "url(" + image + ")" }}
        />
      </div>
      <div className={styles.title}>{title}</div>
    </div>
  );
});

import { setWith, TypedReducer } from "redoodle";
import { ScottflixNavTab } from "../data/ScotflixData";
import { ScottflixActions } from "./ScottflixActions";
import { getInitialState, ScottflixState } from "./ScottflixState";

export const scottflixReducer = TypedReducer.builder<ScottflixState>()
    .withHandler(
        ScottflixActions.selectTab.TYPE,
        (state: ScottflixState, selectedTab: ScottflixNavTab) => {
            return setWith(state, {
                selectedTab: selectedTab
            });
        }
    )
    .withDefaultHandler((_state) => getInitialState())
    .build();
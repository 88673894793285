export interface Artist {
    name: { first: string; last: string; nickname?: string };
  }
  
  export enum Artists {
    PETER = "PETER",
    SARA = "SARA",
    JAN = "JAN",
    RIDG = "RIDG",
    ANNA = "ANNA",
    YAN = "YAN",
    JORDAN = "JORDAN",
    GRIFFIN = "GRIFFIN",
    // real
    CARRIE_UNDERWOOD = "CARRIE_UNDERWOOD",
    IDAHO = "IDAHO",
    MILEY_CYRUS = "MILEY_CYRUS",
    JAY_Z = "JAY_Z",
    DONALD_TRUMP = "DONALD_TRUMP",
    ALAN_JACKSON = "ALAN_JACKSON",
    SHAKIRA = "SHAKIRA",
    TAYLOR_SWIFT = "TAYLOR_SWIFT",
    JACK_JOHNSON = "JACK_JOHNSON",
    SHANIA_TWAIN = "SHANIA_TWAIN",
    THE_FRAY = "THE_FRAY",
    BACKSTREET_BOYS = "BACKSTREET_BOYS",
    ALL_AMERICAN_REJECTS = "ALL_AMERICAN_REJECTS",
    ENRIQUE_IGLESIAS = "ENRIQUE_IGLESIAS",
    THE_POLICE = "THE_POLICE",
    THE_TEMPER_TRAP = "THE_TEMPER_TRAP",
    DJ_KHALED = "DJ_KHALED",
    BAHA_MEN = "BAHA_MEN",
    FLORENCE = "FLORENCE",
    DURAN_DURAN = "DURAN_DURAN",
    BRUCE_SPRINGSTEEN = "BRUCE_SPRINGSTEEN",
    KANYE_WEST = "KANYE_WEST",
    RED_HEADED_WOODPECKERS = "RED_HEADED_WOODPECKERS",
    LOONS = "LOONS",
    NICKELBACK = "NICKELBACK",
    NELLY_FURTADO = "NELLY_FURTADO",
    CARLY_SIMON = "CARLY_SIMON",
    MUMFORD_AND_SONS = "MUMFORD_AND_SONS",
    HALL_AND_OATES = "HALL_AND_OATES",
    KENDRICK_LAMAR = "KENDRICK_LAMAR",
    PETER_THIEL = "PETER_THIEL",
    JOHN_DENVER = "JOHN_DENVER",
    DUA_LIPA = "DUA_LIPA",
    SIMPLE_PLAN = "SIMPLE_PLAN",
    SHAWN_MENDES = "SHAWN_MENDES",
    RICKY_MARTIN = "RICKY_MARTIN",
    BEACH_BOYS = "BEACH_BOYS",
    TRAVIE_MCCOY = "TRAVIE_MCCOY",
    ARETHA_FRANKLIN = "ARETHA_FRANKLIN",
    REEL_BIG_FISH = "REEL_BIG_FISH",
    TROPOSPHERE_MONITORING = "TROPOSPHERE_MONITORING",
    BEYONCE = "BEYONCE",
    SIGALA = "SIGALA",
    ANDY_GRAMMER = "ANDY_GRAMMER",
    CHRISTINA_AGUILERA = "CHRISTINA_AGUILERA",
    B_WITCHED = "B_WITCHED",
    MORGAN_WALLEN = "MORGAN_WALLEN",
    TOBY_KEITH = "TOBY_KEITH",
    CHRIS_JANSON = "CHRIS_JANSON",
    THE_OFFSPRING = "THE_OFFSPRING",
    AWOLNATION = "AWOLNATION",
    STYX = "STYX",
    THE_BEATLES = "THE_BEATLES",
    WILLIEN_NELSON = "WILLIE_NELSON",
    OTIS_REDDING = "OTIS_REDDING",
    SAM_HUNT = "SAM_HUNT",
    MOON_TAXI = "MOON_TAXI",
    COLEMAN_HELL = "COLEMAN_HELL",
    BLEACHERS = "BLEACHERS",
    DAN_AND_SHAY = "DAN_AND_SHAY",
    ARIEL_FERNANDEZ = "ARIEL_FERNANDEZ",
    LUDACRIS = "LUDACRIS",
    FIFTH_HARMONY = "FIFTH_HARMONY",
    SAM_SMITH = "SAM_SMITH",
    WALK_THE_MOON = "WALK_THE_MOON",
    PUDDLE_OF_MUDD = "PUDDLE_OF_MUDD",
    DAYA = "DAYA",
    EVAN_AND_JARON = "EVAN_AND_JARON",
    DISC_GOLFERS = "DISC_GOLFERS",
    CYNDI_LAUPER = "CYNDI_LAUPER",
    WHAM = "WHAM",
    ABBA = "ABBA",
  }
  
  export const ARTISTS: { [key in Artists]: Artist } = {
    PETER: {
      name: { first: "Peter", last: "Scott"},

    },
    SARA: {
      name: { first: "Sara", last: "Scott" },
    },
    JAN: {
      name: { first: "Jan", last: "Englund" },
    },
    RIDG: {
      name: { first: "Ridg", last: "Scott" },
    },
    ANNA: {
      name: { first: "Anna", last: "Scott" },
    },
    YAN: {
      name: { first: "Yan", last: "Azdoud" },
    },
    JORDAN: {
      name: { first: "Jordan", last: "O'Neill" },
    },
    GRIFFIN: {
      name: {first: "Griffin", last: "Dog"},
    },
    // real-ish
    CARRIE_UNDERWOOD: {
      name: {first: "Carrie", last: "Underwood"}
    },
    IDAHO: {
      name: {first: "Idaho", last: ""}
    },
    MILEY_CYRUS: {
      name: {first: "Miley", last: "Cyrus"}
    },
    JAY_Z: {
      name: {first: "JAY-Z", last: ""}
    },
    DONALD_TRUMP: {
      name: {first: "Donald", last: "Trump"}
    },
    ALAN_JACKSON: {
      name: {first: "Alan", last: "Jackson"}
    },
    SHAKIRA: {
      name: {first: "Shakira", last: ""}
    },
    TAYLOR_SWIFT: {
      name: {first: "Taylor", last: "Swift"}
    },
    JACK_JOHNSON: {
      name: {first: "Jack", last: "Johnson"}
    },
    SHANIA_TWAIN: {
      name: {first: "Shania", last: "Twain"}
    },
    THE_FRAY: {
      name: {first: "The Fray", last: ""}
    },
    BACKSTREET_BOYS: {
      name: { first: "Backstreet", last: "Boys"}
    },
    ALL_AMERICAN_REJECTS: {
      name: { first: "The All-American Rejects", last: ""}
    },
    ENRIQUE_IGLESIAS: {
      name: {first: "Enrique", last: "Iglesias"}
    },
    THE_POLICE: {
      name: {first: "The Police", last: ""}
    },
    THE_TEMPER_TRAP: {
      name: {first: "The Temper Trap", last: ""}
    },
    DJ_KHALED: {
      name: { first: "DJ Khaled", last: ""},
    },
    BAHA_MEN: {
      name: {first: "Baha", last: "Men"},
    },
    FLORENCE: {
      name: {first: "Florence + The Machine", last: ""}
    },
    DURAN_DURAN: {
      name: {first: "Duran", last: "Duran"}
    },
    BRUCE_SPRINGSTEEN: {
      name: {first: "Bruce", last: "Springsteen"}
    },
    KANYE_WEST: {
      name: {first: "Kanye", last: "West"}
    },
    RED_HEADED_WOODPECKERS: {
      name: {first: "Red-Headed", last: "Woodpeckers"}
    },
    LOONS: {
      name: {first: "Loons", last: ""},
    },
    NICKELBACK: {
      name: {first: "Nickelback", last: ""},
    },
    NELLY_FURTADO: {
      name: {first: "Nelly", last: "Furtado"},
    },
    CARLY_SIMON: {
      name: {first: "Carly", last: "Simon"}
    },
    MUMFORD_AND_SONS: {
      name: { first: "Mumford and Sons", last: ""}
    },
    HALL_AND_OATES: {
      name: { first: "Hall and Oates", last: ""}
    },
    KENDRICK_LAMAR: {
      name: {first: "Kendrick", last: "Lamar"}
    },
    PETER_THIEL: {
      name: {first: "Peter", last: "Thiel"}
    },
    JOHN_DENVER: {
      name: {first: "John", last: "Denver"}
    },
    DUA_LIPA: {
      name: {first: "Dua", last: "Lipa"}
    },
    SIMPLE_PLAN: {
      name: {first: "Simple", last: "Plan"}
    },
    SHAWN_MENDES: {
      name: {first: "Shawn", last: "Mendes"}
    },
    RICKY_MARTIN: {
      name: {first: "Ricky", last: "Martin"}
    },
    BEACH_BOYS: {
      name: {first: "The Beach Boys", last: ""}
    },
    TRAVIE_MCCOY: {
      name: {first: "Travie", last: "McCoy"}
    },
    ARETHA_FRANKLIN: {
      name: {first: "Aretha", last: "Franklin"}
    },
    REEL_BIG_FISH: {
      name: {first: "Reel Big Fish", last: ""}
    },
    TROPOSPHERE_MONITORING: {
      name: {first: "Troposphere", last: "Monitoring"}
    },
    BEYONCE: {
      name: {first: "Beyonce", last: ""}
    },
    SIGALA: {
      name: {first: "Sigala", last: ""}
    },
    ANDY_GRAMMER: {
      name: {first: "Andy", last: "Grammer"}
    },
    CHRISTINA_AGUILERA: {
      name: {first: "Christina", last: "Aguilera"}
    },
    B_WITCHED: {
      name: {first: "B*Witched", last: ""}
    },
    MORGAN_WALLEN: {
      name: {first: "Morgan", last: "Wallen"}
    },
    TOBY_KEITH: {
      name: {first: "Toby", last: "Keith"}
    },
    CHRIS_JANSON: {
      name: {first: "Chris", last: "Janson"}
    },
    THE_OFFSPRING: {
      name: {first: "The Offspring", last: ""}
    },
    AWOLNATION: {
      name: {first: "AWOLNATION", last: ""}
    },
    STYX: {
      name: {first: "Styx", last: ""}
    },
    THE_BEATLES: {
      name: {first: "The Beatles", last: ""}
    },
    WILLIE_NELSON: {
      name: {first: "Willie", last: "Nelson"}
    },
    OTIS_REDDING: {
      name: {first: "Otis", last: "Redding"}
    },
    SAM_HUNT: {
      name: {first: "Sam", last: "Hunt"}
    },
    MOON_TAXI: {
      name: {first: "Moon", last: "Taxi"}
    },
    COLEMAN_HELL: {
      name: {first: "Coleman", last: "Hell"}
    },
    BLEACHERS: {
      name: {first: "Bleachers", last: ""}
    },
    DAN_AND_SHAY: {
      name: {first: "Dan + Shay", last: ""}
    },
    ARIEL_FERNANDEZ: {
      name: {first: "Ariel", last: "Fernandez"}
    },
    LUDACRIS: {
      name: {first: "Ludacris", last: ""}
    },
    FIFTH_HARMONY: {
      name: {first: "Fifth", last: "Harmony"}
    },
    SAM_SMITH: {
      name: {first: "Sam", last: "Smith"}
    },
    WALK_THE_MOON: {
      name: {first: "WALK THE MOON", last: ""}
    },
    PUDDLE_OF_MUDD: {
      name: {first: "Puddle of Mudd", last: ""}
    },
    DAYA: {
      name: {first: "Daya", last: ""}
    },
    EVAN_AND_JARON: {
      name: {first: "Evan and Jaron", last: ""}
    },
    DISC_GOLFERS: {
      name: {first: "Disc", last: "Golfers"}
    },
    CYNDI_LAUPER: {
      name: {first: "Cyndi", last: "Lauper"}
    },
    WHAM: {
      name: {first: "Wham!", last: ""}
    },
    ABBA: {
      name: {first: "ABBA", last: ""}
    }
  };
  
  export function getDisplayNameForArtist(artist: Artist) {
    if (artist.name.nickname) {
      return (
        artist.name.first + ' "' + artist.name.nickname + '" ' + artist.name.last
      );
    }
    return artist.name.first + " " + artist.name.last;
  }
import * as React from "react";
import styles from "../../styles/scottify/ScottifyBody.module.scss";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import { Track } from "../data/ScottifyData";
import { getDisplayNameForArtist } from "../data/Artists";

declare namespace TrackRow {
  export interface Props {
    track: Track;
    trackNumber: number;
  }
}

// eslint-disable-next-line no-redeclare
export default class TrackRow extends React.Component<TrackRow.Props> {
  render() {
    const { track, trackNumber } = this.props;

    return (
      <div className={classNames(styles.tracksTableRow, styles.tableRow)}>
        <div className={classNames(styles.tableCol, styles.colPlay)}>
          {track.link !== "" ? (<Icon
            className={styles.playIcon}
            icon={IconNames.PLAY}
            onClick={this.handleClickTrack}
          />) : (<Icon
            className={styles.blankIcon}
            icon={IconNames.BLANK}
          />)}
        </div>
        <div className={classNames(styles.tableCol, styles.colTrack, styles.center)}>
          {trackNumber}
        </div>
        <div className={classNames(styles.tableCol, styles.colTitle)}>{track.title}</div>
        <div className={classNames(styles.tableCol, styles.colArtist)}>
          {getDisplayNameForArtist(track.artist)}
        </div>
        <div className={classNames(styles.tableCol, styles.colDuration)} />
      </div>
    );
  }

  private handleClickTrack = () => {
    window.open(this.props.track.link, "_blank");
  };
}

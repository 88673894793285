import { createSelector } from "reselect";
import { GlobalState } from "../../state/globalState";

export const getBuzzFeedSelectedTab = createSelector(
  (state: GlobalState) => state.buzzFeedState,
  (buzzFeedState) => {
    return buzzFeedState.selectedTab;
  }
);

export const getBuzzFeedSelectedContestant = createSelector(
  (state: GlobalState) => state.buzzFeedState,
  (buzzFeedState) => {
    return buzzFeedState.selectedContestant;
  }
);

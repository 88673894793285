import { setWith, TypedReducer } from "redoodle";
import { BuzzFeedNavTab, Contestants } from "../data/BuzzFeedData";
import { BuzzFeedActions } from "./BuzzFeedActions";
import { BuzzFeedState, getInitialBuzzFeedState } from "./BuzzFeedState";

export const buzzFeedReducer = TypedReducer.builder<BuzzFeedState>()
  .withHandler(
    BuzzFeedActions.selectTab.TYPE,
    (state: BuzzFeedState, selectedTab: BuzzFeedNavTab) => {
      return setWith(state, {
        selectedTab: selectedTab,
      });
    }
  )
  .withHandler(
    BuzzFeedActions.selectContestant.TYPE,
    (
      state: BuzzFeedState,
      selectedContestant: keyof typeof Contestants | undefined
    ) => {
      return setWith(state, {
        selectedContestant,
      });
    }
  )
  .withDefaultHandler((_state) => getInitialBuzzFeedState())
  .build();

import { Button, Menu, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { MenuItem2, Popover2 } from "@blueprintjs/popover2";
import * as React from "react";
import { useDispatch } from "react-redux";
import styles from "../../styles/buzzfeed/BuzzFeedMobileHeaderBar.module.scss";
import { BuzzFeedNavTab } from "../data/BuzzFeedData";
import { BuzzFeedActions } from "../state/BuzzFeedActions";

export const BuzzFeedMobileHeaderBar = React.memo(
  function BuzzFeedMobileHeaderBarInternal() {
    const dispatch = useDispatch();

    const goToBuzzFeedHome = React.useCallback(() => {
      dispatch(BuzzFeedActions.selectTab(BuzzFeedNavTab.HOME));
    }, [dispatch]);

    const goToBuzzFeedRules = React.useCallback(() => {
      dispatch(BuzzFeedActions.selectTab(BuzzFeedNavTab.RULES));
    }, [dispatch]);

    return (
      <div className={styles.mobileHeader}>
        <div className={styles.headerLogo}>
          <img
            className={styles.headerLogoImage}
            src="/img/buzzfeed/buzzfeed_logo.png"
            alt=""
            onClick={goToBuzzFeedHome}
          />
        </div>
        <div className={styles.headerSpacer} />
        <Popover2
          className={styles.buzzFeedMobileHeaderMenuPopover}
          content={
            <Menu className={styles.buzzFeedMobileHeaderNavBarMenu}>
              <MenuItem2
                className={styles.mobileNavBarItem}
                text="Home"
                onClick={goToBuzzFeedHome}
              />
              <MenuItem2
                className={styles.mobileNavBarItem}
                text="Rules"
                onClick={goToBuzzFeedRules}
              />
            </Menu>
          }
          position={Position.BOTTOM_RIGHT}
          minimal={true}
        >
          <Button icon={IconNames.MENU} minimal={true} />
        </Popover2>
      </div>
    );
  }
);

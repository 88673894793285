import * as React from "react";
import scottifyStyles from "../styles/scottify/Scottify.module.scss";
import pageStyles from "../styles/shared/Page.module.scss";
import classNames from "classnames";
import { Playlist, PLAYLISTS, RIVAL_2019_PLAYLIST } from "./data/ScottifyData";
import { isMobile } from "react-device-detect";
import ScottifyMobileBody from "./components/ScottifyMobileBody";
import ScottifyBody from "./components/ScottifyBody";
import { Link } from "react-router-dom";
import { Icon, ProgressBar } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import Controls, { CURRENT_SONG_TITLE, CURRENT_SONG_ARTIST } from "./components/Controls";

declare namespace Scottify {
  export interface Props {}
  export interface State {
    selectedPlaylist: Playlist;
  }
}


// eslint-disable-next-line no-redeclare
export default class Scottify extends React.Component<
Scottify.Props,
Scottify.State
> {
  state: Scottify.State = {
    selectedPlaylist: PLAYLISTS[0]
  };

  render() {
  
    return (
      <div className={classNames(pageStyles.page, scottifyStyles.scottifyPage, {[scottifyStyles.mobile]: isMobile})}>
          {isMobile ? this.renderHeader() : null}
          <div className={scottifyStyles.scottifyPageBody}>
            {isMobile ? (
              <ScottifyMobileBody
                playlist={this.state.selectedPlaylist}
                selectPlaylist={this.selectPlaylist}
                nextPlaylist={this.nextPlaylist}
                previousPlaylist={this.previousPlaylist}
              />
            ) : (
              <ScottifyBody
                playlist={this.state.selectedPlaylist}
                selectPlaylist={this.selectPlaylist}
                nextPlaylist={this.nextPlaylist}
                previousPlaylist={this.previousPlaylist}
              />
            )}
          </div>
          <div className={scottifyStyles.scottifyPageFooter}>
            <Controls />
          </div>
      </div>
    );
  }

  private handleClickPlay = () => {
    window.open(
      "https://open.spotify.com/playlist/2upvTnzAS3EtKhxv92kg4m",
      "_blank"
    );
  };

  private selectPlaylist = (playlist: Playlist) => {
    this.setState({ selectedPlaylist: playlist });
  };

  private nextPlaylist = () => {
    const index = _.findIndex(PLAYLISTS, this.state.selectedPlaylist);
    if (index != null && index + 1 < PLAYLISTS.length) {
      this.setState({
        selectedPlaylist: PLAYLISTS[index + 1]
      });
    }
  };

  private previousPlaylist = () => {
    const index = _.findIndex(PLAYLISTS, this.state.selectedPlaylist);
    if (index != null && index > 0) {
      this.setState({
        selectedPlaylist: PLAYLISTS[index - 1]
      });
    }
  };

  private renderHeader = () => {
    return (
      <div className={scottifyStyles.scottifyPageHeader}>
        <Link className={scottifyStyles.webNavBarImage} to="/scottify">
          <img
            className={scottifyStyles.rosterLogoImage}
            src="/img/scottify/ScottifyLogo.png"
            alt=""
          />
        </Link>
        <div className={scottifyStyles.horizontalSpacer} />
        {/* <Link className={scottifyStyles.webNavBarImage} to="/">
          <img
            className={scottifyStyles.rivalLogoImage}
            src="/img/scottify/Rlogo_white_small.png"
            alt=""
          />
        </Link> */}
      </div>
    );
  };


  private renderControlsMobile = (
    daysPassed: string,
    progressVal: number,
    daysTotal: number
  ) => {
    return (
      <React.Fragment>
        <div className={scottifyStyles.controlsBarMobile}>
          <div className={scottifyStyles.progressBar}>
            <ProgressBar value={progressVal} animate={false} stripes={false} />
          </div>
          <div className={scottifyStyles.mainControls}>
            <Icon
              className={classNames(scottifyStyles.controlIcon, scottifyStyles.heart)}
              icon={IconNames.HEART}
              iconSize={14}
              onClick={() => this.selectPlaylist(RIVAL_2019_PLAYLIST)}
            />
            <div className={scottifyStyles.title}>
              {CURRENT_SONG_TITLE}
              <Icon
                className={scottifyStyles.titleIcon}
                icon={IconNames.DOT}
                iconSize={8}
                onClick={this.handleClickPlay}
              />
              <span className={scottifyStyles.mainTrackArtist}>Rival 2019</span>
            </div>
            <Icon
              className={classNames(scottifyStyles.controlIcon, scottifyStyles.play)}
              icon={IconNames.PLAY}
              iconSize={14}
              onClick={this.handleClickPlay}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  private renderControlsWeb = (
    daysPassed: string,
    progressVal: number,
    daysTotal: number
  ) => {
    return (
      <React.Fragment>
        <div className={scottifyStyles.controlsBar}>
          <Icon
            className={scottifyStyles.controlIcon}
            icon={IconNames.RANDOM}
            iconSize={12}
          />
          <Icon className={scottifyStyles.controlIcon} icon={IconNames.STEP_BACKWARD} />
          <Icon
            className={classNames(scottifyStyles.controlIcon, scottifyStyles.play)}
            icon={IconNames.PLAY}
            iconSize={18}
            onClick={this.handleClickPlay}
          />
          <Icon className={scottifyStyles.controlIcon} icon={IconNames.STEP_FORWARD} />
          <Icon
            className={scottifyStyles.controlIcon}
            icon={IconNames.REFRESH}
            iconSize={12}
          />
        </div>
        <div className={scottifyStyles.progressBar}>
          <span className={scottifyStyles.durationMarker}>{daysPassed}</span>
          <ProgressBar value={progressVal} animate={false} stripes={false} />
          <span className={scottifyStyles.durationMarker}>{daysTotal}</span>
        </div>
        <div className={scottifyStyles.mainTrackTitle}>
          {CURRENT_SONG_TITLE}
          <span className={scottifyStyles.mainTrackArtist}>{CURRENT_SONG_ARTIST}</span>
        </div>
      </React.Fragment>
    );
  };


}

import * as React from "react";
import { useDispatch } from "react-redux";
import styles from "../../styles/alone/ContestantsList.module.scss";
import { CONTESTANTS } from "../data/AloneData";
import { AloneActions } from "../state/AloneActions";

export const ContestantsList = React.memo(function ContestantsListInternal() {
  const dispatch = useDispatch();

  return (
    <div className={styles.contestantsList}>
      {CONTESTANTS.map((contestant) => (
        <div
          key={contestant.key + "_tile"}
          className={styles.contestantTile}
          onClick={() =>
            dispatch(AloneActions.selectContestant(contestant.key))
          }
        >
          <div className={styles.tilePic}>
            {contestant.tileImgUrl != null && (
              <span>
                <img src={contestant.tileImgUrl} alt="" />
              </span>
            )}
          </div>
          <div className={styles.tileMetadata}>
            <div className={styles.tileName}>{contestant.name}</div>
          </div>
        </div>
      ))}
    </div>
  );
});

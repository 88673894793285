import * as React from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import styles from "../../styles/alone/ShowPreviewBody.module.scss";
import { AloneNavTab } from "../data/AloneData";
import { AloneActions } from "../state/AloneActions";
import { ShowPreview } from "./ShowPreview";

export const ShowPreviewBody = React.memo(function ShowPreviewBodyInternal() {
  const dispatch = useDispatch();

  const selectFeaturedShow = useCallback(() => {
    dispatch(AloneActions.selectTab(AloneNavTab.CONTESTANTS));
  }, [dispatch]);

  return (
    <div className={styles.showPreviewBody}>
      <div className={styles.featuredShowContainer}>
        <ShowPreview
          tag="This Holiday Season"
          title="ALONE: Scotts vs. Nots"
          subtitle="New Episodes Wednesdays at 8/7c and Stream the Next Day"
          action="Meet this seasons contestants"
          selectShow={selectFeaturedShow}
          image="/img/alone/ScottFamilyPhoto.jpg"
        />
      </div>
      <div className={styles.secondaryShowContainer}>
        <div className={styles.showContainer}>
          <ShowPreview
            tag="Next Season"
            title="The entire Rameson Family joins ALONE"
            subtitle="Is it really ALONE if they are all together? Stay tuned to find out!"
            image="/img/alone/RamesonsFamilyPhoto.jpeg"
          />
        </div>
        <div className={styles.showContainer}>
          <ShowPreview
            tag="Recommended for You"
            title="Matt"
            subtitle="If you liked ALONE: Scotts vs. Nots, you'll tolerate Matt!"
            image="/img/alone/Matt.jpeg"
          />
        </div>
      </div>
    </div>
  );
});

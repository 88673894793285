import classNames from "classnames";
import * as React from "react";
import { PELOTON_RULES } from "../../peloton/data/Rules";
import styles from "../../styles/alone/AloneHome.module.scss";
import rulesStyles from "../../styles/alone/RulesBody.module.scss";

export const RulesBody = React.memo(function RulesBodyInternal() {
  return (
    <div className={classNames(styles.body, rulesStyles.rulesBody)}>
      <div className={styles.bodyHeader}>
        <div className={styles.leftHeader} />
        <div className={styles.title}>Rules</div>
        <div className={styles.rightHeader} />
      </div>
      {PELOTON_RULES.map((rule) => (
        <div className={rulesStyles.ruleContainer} key={rule.rule}>
          <div className={rulesStyles.ruleImageContainer}>
            <img className={rulesStyles.ruleImage} src={rule.image} alt="" />
          </div>
          <div className={rulesStyles.ruleText}>
            {rule.heading && (
              <div className={rulesStyles.ruleHeader}>{rule.heading}</div>
            )}
            <div className={rulesStyles.ruleDescription}>{rule.rule}</div>
          </div>
        </div>
      ))}
    </div>
  );
});

export enum AloneNavTab {
  HOME = "HOME",
  RULES = "RULES",
  CONTESTANTS = "CONTESTANTS",
  CONTESTANT_BIO = "CONTESTANT_BIO",
}

export enum Contestants {
  JAN = "JAN",
  RIDG = "RIDG",
  ANNA = "ANNA",
  YAN = "YAN",
  SARA = "SARA",
  JORDAN = "JORDAN",
  PETER = "PETER",
  TOTS = "TOTS",
  OJ = "OJ",
  GRIFFIN = "GRIFFIN",
  NUTMEG = "NUTMEG",
}

export interface Contestant {
  key: keyof typeof Contestants;
  name: string;
  age: string;
  hometown: string;
  profession: string;
  tileImgUrl: string;
  primaryImgUrl: string;
  secondaryImgUrl: string;
  tertiaryImgUrl: string;
  bio: string;
  items: string[];
}

export const CONTESTANTS: Contestant[] = [
  {
    key: Contestants.JAN,
    name: "Jan Englund",
    age: "",
    hometown: "",
    profession: "",
    tileImgUrl: "/img/alone/thumbnails/Thumbnail_Jan.png",
    primaryImgUrl: "/img/alone/primary/Primary_Jan.jpeg",
    secondaryImgUrl: "/img/alone/secondary/Secondary_Jan.jpeg",
    tertiaryImgUrl: "/img/alone/tertiary/Tertiary_Jan.jpeg",
    items: [
      `Orange backpack`,
      `1 North America Birds Book`,
      `1 South America Birds Book`,
      `1 East Africa Birds Book`,
      `Binoculars`,
      `Extra camera batteries (yes, she knows extra batteries will be supplied)`,
      `Notes app for recording bird sightings`,
      `Birch bark`,
      `Red hat`,
      `Empty suitcase for souveniers`,
      `Cool rock`,
    ],
    bio: `Shutterfly super-user and chicken photographer Jan Englund is grateful for the chance to be on this season of Alone and prove that she can still handle the outdoors despite years of shirking any hard labor on backpacking expeditions with her children and going on progressively cushy “glamping” trips. She thinks her talents cultivated as a world-renowned ultimate frisbee phenom and fitness guru will translate well to the harsh conditions on Alone.
    \nPlanning to bring her work bag and laptop so they don't get stolen, Jan views this survival journey as more of a "working vacation" and an “opportunity to catch up on email.” She plans to spend the time alternating between threatening to retire and applying for new grants, while using the exposure to bad-mouth the Gates' Foundation. "If I don't see at least 100 different birds this will be a total waste........." she states with her trademark optimism. “..........JAN/MOM.” In lieu of a shelter, Jan plans to just collect rocks. 
`,
  },
  {
    key: Contestants.RIDG,
    name: "Ridg Scott",
    age: "",
    hometown: "",
    profession: "",
    tileImgUrl: "/img/alone/thumbnails/Thumbnail_Ridg.png",
    primaryImgUrl: "/img/alone/primary/Primary_Ridg.jpeg",
    secondaryImgUrl: "/img/alone/secondary/Secondary_Ridg.jpeg",
    tertiaryImgUrl: "/img/alone/tertiary/Tertiary_Ridg.jpeg",
    items: [
      `1 decaf black tea bag (used)`,
      `Dick Francis book`,
      `Blue cruise`,
      `Extra dryer lint for starting fires`,
      `Two-day old Wallstreet Journal`,
      `Another Dick Francis book`,
      `1500 feet of docking line`,
      `Blueprints for the addition`,
      `Grocery list`,
      `Extra ropes`,
    ],
    bio: `When asked to sum up his approach in one word, Ridg answered, "Pontification. Also, money. Also, more money." His secret weapon will be his credit card, he asserts, as he plans to buy not only the land where the season is filmed, but also the production crew, and even the entire History Channel. "I'm at a stage in my life where I feel like I should be spending money," he explains while towing his brand-new sailboat with an even newer escalade. 
    \nAfter a grueling 6-month isolation in the rural upper peninsula of Michigan earlier this year, Ridg feels prepared to face the psychological challenges presented on the show. "Honestly I don't even notice when there aren't other people around me," he explains. He plans to build a large and perfectly functional shelter, and then add an oversized, handicap-accessible new addition that should be ready in time for the contestants of season 27. 
    `,
  },
  {
    key: Contestants.ANNA,
    name: "Anna Scott",
    age: "",
    hometown: "",
    profession: "",
    tileImgUrl: "/img/alone/thumbnails/Thumbnail_Anna.png",
    primaryImgUrl: "/img/alone/primary/Primary_Anna.jpeg",
    secondaryImgUrl: "/img/alone/secondary/Secondary_Anna.jpeg",
    tertiaryImgUrl: "/img/alone/tertiary/Tertiary_Anna.jpeg",
    items: [
      `Obnoxious side-shield sunglasses`,
      `Equally obnoxious replacement side-shield sunglasses`,
      `Niche foraging book for incorrect climate`,
      `Business cards`,
      `Moss`,
      `City Official Certificate`,
      `Neutral to positive attitude about Project Canary`,
      `Biodegradable fishing line`,
      `Mink stole`,
      `Macrame plant holder`,
      `TikTok`,
    ],
    bio: `Executive homemaker and professional know-it-all Anna Scott is taking a break from soliciting bribes on the campaign trail to join the cast of Alone this season. After watching hours of 15 second TikToks on wilderness survival, macrame, and hip mobility, Anna feels like she is more than prepared for this ordeal, which she is referring to as “a work event” and describing as a "Women's Empowerment Conference."  
    \nShe plans to rely on her innate sense of superiority to win the competition and is opting out of the mandatory safety training all contestants undergo prior to their time in the wilderness. She will be using the exposure to raise awareness for her new pet project - severance packages for the 1% - which she will be promoting with her hashtag, #compensateAnna.
    `,
  },
  {
    key: Contestants.YAN,
    name: "Yan Azdoud",
    age: "",
    hometown: "",
    profession: "",
    tileImgUrl: "/img/alone/thumbnails/Thumbnail_Yan.png",
    primaryImgUrl: "/img/alone/primary/Primary_Yan.jpeg",
    secondaryImgUrl: "/img/alone/secondary/Secondary_Yan.jpeg",
    tertiaryImgUrl: "/img/alone/tertiary/Tertiary_Yan.jpeg",
    items: [
      `Serrano ham leg`,
      `Bandaids for inevitable finger cuts`,
      `Audiobook`,
      `Headphones`,
      `A roux`,
      `New tattoo`,
      `Bocce`,
      `Laser cutter`,
      `Slippers`,
      `DIY Hot-Tub Kit`,
    ],
    bio: `A poorly timed health kick has Yan worried about his chances to outlast some of the heartier competition. But he has little doubt in his skills as an outdoorsman, and plans to use the time to develop and market a new line of natural liqueurs. Unable to limit himself to one business venture at a time, he will also be developing a stick-and-poke tattoo vending machine that he is planning to pitch to the medical team during weight checks. 
    \nIn addition to his creative approaches to combat the solitude, his main strategy is sabotage. "I taught all those idiots the wrong information about mushrooms," he explains. "So I'm pretty sure they'll all die and I'll win."
    `,
  },
  {
    key: Contestants.SARA,
    name: "Sara Scott",
    age: "",
    hometown: "",
    profession: "",
    tileImgUrl: "/img/alone/thumbnails/Thumbnail_Sara.png",
    primaryImgUrl: "/img/alone/primary/Primary_Sara.jpeg",
    secondaryImgUrl: "/img/alone/secondary/Secondary_Sara.jpeg",
    tertiaryImgUrl: "/img/alone/tertiary/Tertiary_Sara.jpeg",
    items: [
      `Cookie/biscuit combo cutter`,
      `Blue water bottle`,
      `20 Kg kettlebell`,
      `Flossie`,
      `Teen romance novels`,
      `Thrifted sleeping bag`,
      `Sewing kit for underwear tears`,
      `Two persimmons`,
      `Wilting bunch of kale from fridge`,
      `Library card`,
    ],
    bio: `Fresh off of a grueling “two week off” segment of her patented “two on, two off” schedule, Sara joins Alone excited to relax and finally get back to work. She plans to succeed using intimidation and willpower. “Is that all you got, thunderstorm? I peed twice that much just this morning.” No apex predator will mess with her because they will be scared off by the sound of iron weights dropping on the rocky shore. She’s not confident in her hunting ability, but she plans to lean heavily on her hiking prowess; planning to become the first contestant to steal from other players.
    \nTo combat boredom, Sara plans to continue keeping Clinica Latina (Spanish Free Clinic in Ohio) alive by being the only provider who deals with lab results and by making daily calendars filled with to-do items, each stretching three months into the future.
    \nWhen asked what she would do if she won, Sara responded “Honestly, donate the money and probably stay out there. Can I compete in another season, ideally, one that is twice as long?”`,
  },
  {
    key: Contestants.JORDAN,
    name: "Jordan O'Neill",
    age: "",
    hometown: "",
    profession: "",
    tileImgUrl: "/img/alone/thumbnails/Thumbnail_Jordan.png",
    primaryImgUrl: "/img/alone/primary/Primary_Jordan.jpeg",
    secondaryImgUrl: "/img/alone/secondary/Secondary_Jordan.jpeg",
    tertiaryImgUrl: "/img/alone/tertiary/Tertiary_Jordan.jpeg",
    items: [
      `Blow Dryer`,
      `Tuft of Griffin's hair`,
      `Blue light glasses`,
      `That Ankle Mobility device he bought online but never used but figured now would be a good time to start`,
      `Tummy Time Tea`,
      `Ted Lass-dough`,
      `Scented candle`,
      `Bellybutton lint `,
      `Steroid cream for inevitable poison ivy`,
      `Sleep Tracker`,
    ],
    bio: `Former fashion icon and amateur time thief Jordan O’Neill decided to sign up for this grueling 100-day survival challenge after buying but not reading a book about trees. “Seeing that book cover just made me feel really connected to the outdoors,” he explains. “I felt like that was a strong enough reason to base a life decision on.”
    \nAfter embracing charcoal toothpaste, Jordan feels certain he can master the other challenging elements of primitive living. He has spent the last year dedicating himself to researching the pressing challenges of our time, such as how to make the perfect cup of aeropress coffee and which dog bed is most comfortable. He believes this rigorous attention to detail will serve him well in the wild.
    \nNeeding a creative way to ride out his non-compete with Epic, and facing a dwindling store of Marriott Hotel points, Jordan is hopeful he will make it the entire 100-days. His primary strategy for survival revolves around finding a rock shaped like Griffin, and then carrying on his usual home routine pretty much unchanged.
    `,
  },
  {
    key: Contestants.PETER,
    name: "Peter Scott",
    age: "",
    hometown: "",
    profession: "",
    tileImgUrl: "/img/alone/thumbnails/Thumbnail_Peter.png",
    primaryImgUrl: "/img/alone/primary/Primary_Peter.jpeg",
    secondaryImgUrl: "/img/alone/secondary/Secondary_Peter.jpeg",
    tertiaryImgUrl: "/img/alone/tertiary/Tertiary_Peter.jpeg",
    items: [
      `Ice axe`,
      `Picture of Matt`,
      `Yoga mat`,
      `Climbing shoes`,
      `Chalk bag`,
      `EPIC Ski Pass (just in case)`,
      `Miso soup`,
      `Oofos`,
      `Leftover insulation from van`,
      `GPS to locate Matt `,
      `No thanks, I don't need a sleeping pad`,
    ],
    bio: `Professional bear-whisperer, aspiring chef, and Palantir bad-boy Peter Scott initially signed up for Alone thinking it was a self-help course for perpetually single individuals. After realizing it was a survival-based reality show, he declined to participate. 
    \nHowever, finding himself in dire financial straits following the purchase of his first million property (which will be furnished by his Mother), he could no longer afford to buy food or pay rent in New York City. He finally agreed to be on the show after being rejected by plasma donation centers and sperm banks across the state. 
    \nHe will be relying on skills honed during the completion of his Eagle Scout Project, a time in his life that is now widely regarded as his “peak.” Not fully understanding the premise of the show, Peter still holds out hope of finding love among the camera crew. `,
  },
  {
    key: Contestants.TOTS,
    name: "Tots",
    age: "",
    hometown: "",
    profession: "",
    tileImgUrl: "/img/alone/thumbnails/Thumbnail_Tots.png",
    primaryImgUrl: "/img/alone/primary/Primary_Tots.jpeg",
    secondaryImgUrl: "/img/alone/secondary/Secondary_Tots.jpeg",
    tertiaryImgUrl: "/img/alone/tertiary/Tertiary_Tots.jpeg",
    items: [
      `An Idiots Guide to the Outdoors`,
      `Toilet paper`,
      `Kikomen Soy Sauce`,
      `Tide Sport`,
      `Happy Buddha Body Wash`,
      `Llama-themed Welly bandaids`,
      `Super glue for splinter removals`,
      `Delta status card`,
      `Wrist braces`,
      `An americano`,
    ],
    bio: `Underdog and indoorswoman Tots is adamant that she didn't sign up for the wrong show. "I totally know how to 'outdoor,'" she insists with a liberal amount of air quotes as she quickly tries to minimize the Love is Blind application on her computer screen.  While many would view her inability to differentiate small rodents like squirrels and raccoons from larger predators as an impediment, Tots maintains this “species blindness” will help to make her food stores last longer. 
    \nShe plans to rely on her Diamond Medallion Delta status to secure better accommodations on the island and her general stubbornness and inability to admit she is wrong to help her outlast the competition.`,
  },
  {
    key: Contestants.OJ,
    name: "OJ",
    age: "",
    hometown: "",
    profession: "",
    tileImgUrl: "/img/alone/thumbnails/Thumbnail_OJ.png",
    primaryImgUrl: "/img/alone/primary/Primary_OJ.jpeg",
    secondaryImgUrl: "/img/alone/secondary/Secondary_OJ.jpeg",
    tertiaryImgUrl: "/img/alone/tertiary/Tertiary_OJ.jpeg",
    items: [
      `Mirror`,
      `Tesla keys`,
      `Jaw exercise tool`,
      `Snapchat`,
      `Cool sunglasses`,
      `A book`,
      `Small mug that Sara bought him and he treasures dearly`,
      `Naptime`,
      `Desktop disc golf basket`,
      `Cat stickers`,
    ],
    bio: `Newcomer and professional third wheel OJ signed up for this show after he realized he would be able to flip around the camera and see his face. "Initially, I thought no way," he explains. "But then they told me the screen rotates so I actually WOULD be able to see my face the whole time. So I of course said yes at that point. I'm really looking forward to spending this time with myself." 
    \nWhile in real life, his egocentrism may not seem appealing, OJ is sure this trait will be advantageous during the long days in the wilderness. He believes that his experience luxury charter fishing in Alaska this summer, the one semi-outdoorsy thing he has done in his life, will equip him with the survival skills needed to last for 100 days in the wilderness.`,
  },
  {
    key: Contestants.GRIFFIN,
    name: "Griffin",
    age: "",
    hometown: "",
    profession: "",
    tileImgUrl: "/img/alone/thumbnails/Thumbnail_Griffin.png",
    primaryImgUrl: "/img/alone/primary/Primary_Griffin.jpeg",
    secondaryImgUrl: "/img/alone/secondary/Secondary_Griffin.jpeg",
    tertiaryImgUrl: "/img/alone/tertiary/Tertiary_Griffin.jpeg",
    items: [`1 Kong`, `1 Jar peanut butter`, `Snowy`, `7 Balls`],
    bio: `Griffin is planning to drop out immediately, probably within the first 6 hours, due to lack of regular food. He is most looking forward to the bulking before the show starts.`,
  },
  {
    key: Contestants.NUTMEG,
    name: "Nutmeg",
    age: "",
    hometown: "",
    profession: "",
    tileImgUrl: "/img/alone/thumbnails/Thumbnail_Nutmeg.png",
    primaryImgUrl: "/img/alone/primary/Primary_Nutmeg.jpeg",
    secondaryImgUrl: "/img/alone/secondary/Secondary_Nutmeg.jpeg",
    tertiaryImgUrl: "/img/alone/tertiary/Tertiary_Nutmeg.jpeg",
    items: [`Griffin's Bed`, `Griffin's Ball`, `Griffin's Kong`],
    bio: `Nutmeg plans to use her natural instincts to finally catch her first fish or waterfowl. Her backup plan is to find a local wolf-pack and become adopted as a wolf-pup.`,
  },
];

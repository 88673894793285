import * as React from "react";
import homeStyles from "../styles/home/Home.module.scss";
import pageStyles from "../styles/shared/Page.module.scss";
import classNames from "classnames";
import { isMobileOnly } from "react-device-detect";

export const TopSecretSeventeen = React.memo(function TopSecretSeventeenInternal() {
  return (
    <div
      className={classNames(pageStyles.page, homeStyles.homeApp, {
        [homeStyles.mobile]: isMobileOnly
      })}
    >
      <iframe
        src="http://scottsecretsanta.com/pdf/scottsecretsanta2017.pdf"
        title="Scott Secret Santa 2017"
        width="100%"
        height="100%"
      />
    </div>
  );
});
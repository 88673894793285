import * as React from "react";
import homeStyles from "../../styles/home/Home.module.scss";
import { Link } from "react-router-dom";

interface ItemContainerProps {
    linkTo: string;
    backgroundImage: string;
    metadata: string;
}

export const ItemContainer = React.memo(function ItemContainerInternal({ linkTo, backgroundImage, metadata }: ItemContainerProps) {
    return (
        <Link className={homeStyles.itemContainer} to={linkTo}>
            <div className={homeStyles.item} style={{ backgroundImage: `url(${backgroundImage})` }} />
            <div className={homeStyles.itemMetadata}>{metadata}</div>
        </Link>
    );
});


import * as React from "react";
import styles from "../../styles/alone/AloneHome.module.scss";
import { ContestantsList } from "./ContestantsList";

const SHOW_BLURB = `
After 9 successful seasons, the HISTORY® Channel is struggling to find qualified outdoor experts for its hit survival series “Alone.” In a surge of desperation, they have settled for the Scotts and Nots to star in their special Christmas season. 
\nThis atypical season promises to deliver danger, drama, and confusion like viewers have never seen (nor wanted to see) before. 
\nEquipped with just ten items of their choosing (and yes, these are the choices the contestants made), the survivalists are dropped into a remote wilderness valley in The Middle of Nowhere, USA. While documenting their experiences, each participant must survive in total isolation, with hopes of outlasting everyone else to claim the $500,000 prize.
\nNot only must they endure hunger, and the elements, but contestants will also learn just how little they actually like themselves as they are forced to spend weeks of time alone. No camera crews. No gimmicks. It is the ultimate test of the Christmas spirit.`;

export const ContestantsBody = React.memo(function ContestantsBodyInternal() {
  return (
    <div className={styles.body}>
      <div className={styles.bodyHeader}>
        <div className={styles.leftHeader} />
        <div className={styles.title}>About the Show</div>
        <div className={styles.rightHeader} />
      </div>
      <div className={styles.aboutShow}>
        <span>{SHOW_BLURB}</span>
      </div>
      <div className={styles.bodyHeader}>
        <div className={styles.leftHeader} />
        <div className={styles.title}>Meet the Contestants</div>
        <div className={styles.rightHeader} />
      </div>
      <ContestantsList />
    </div>
  );
});

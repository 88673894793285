import { Button, Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import { countBy, find, maxBy } from "lodash";
import * as React from "react";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/buzzfeed/BuzzFeedQuiz.module.scss";
import {
  Contestants,
  DOG_QUIZ_QUESTIONS,
  QUIZ_NAME,
  QUIZ_QUESTIONS,
} from "../data/BuzzFeedData";
import { BuzzFeedActions } from "../state/BuzzFeedActions";
import { getBuzzFeedSelectedContestant } from "../state/BuzzFeedSelectors";
import { Badge } from "./Badge";
import { QuestionContainerText } from "./QuestionContainerText";
import { QuizResultBody } from "./QuizResultBody";
import { ResultListBody } from "./ResultListBody";

export const BuzzFeedQuiz = React.memo(function BuzzFeedQuizInternal() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState(
    new Map<number, number>()
  );
  const [questionSet, setQuestionSet] = useState(QUIZ_QUESTIONS);
  const selectedContestant = useSelector(getBuzzFeedSelectedContestant);

  const handleOpenDialog = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleCloseDialog = useCallback(() => {
    setIsOpen(false);
    dispatch(BuzzFeedActions.selectContestant(undefined));
  }, [setIsOpen, dispatch]);

  const handleSetSelfSelectMode = () => {
    dispatch(BuzzFeedActions.selectContestant(undefined));
  };

  const completeQuiz = useCallback(() => {
    const contestantTypesArray: Array<keyof typeof Contestants> = [];
    selectedAnswers.forEach((answer, question) => {
      const contestants = questionSet[question].answers[answer].contestantTypes;
      for (const contestant of contestants || []) {
        contestantTypesArray.push(contestant);
      }
    });
    const contestantCounts = countBy(contestantTypesArray);
    const keyWithMaxValue = maxBy(
      Object.keys(contestantCounts),
      (key) => contestantCounts[key]
    );
    if (contestantCounts[Contestants.WINN] > 0) {
      dispatch(BuzzFeedActions.selectContestant(Contestants.WINN));
    } else if (keyWithMaxValue != null) {
      const anyTies = find(contestantCounts, (value, key) => {
        return (
          value === contestantCounts[keyWithMaxValue] && key !== keyWithMaxValue
        );
      });
      if (anyTies != null) {
        dispatch(BuzzFeedActions.selectContestant(Contestants.TOTS));
      } else {
        dispatch(
          BuzzFeedActions.selectContestant(keyWithMaxValue as Contestants)
        );
      }
    } else {
      dispatch(BuzzFeedActions.selectContestant(Contestants.TOTS));
    }
    handleOpenDialog();
  }, [dispatch, handleOpenDialog, selectedAnswers, questionSet]);

  const selectAnswer = useCallback(
    (question: number, answer: number) => {
      let newSelectedAnswers = new Map(selectedAnswers);
      if (
        question === 0 &&
        answer === 0 &&
        selectedAnswers.get(0) != null &&
        selectedAnswers.get(0) !== 0
      ) {
        setQuestionSet(DOG_QUIZ_QUESTIONS);
        setSelectedAnswers(new Map(selectedAnswers.set(question, answer)));
        newSelectedAnswers = new Map();
      } else if (
        question === 0 &&
        (answer === 1 || answer === 2) &&
        selectedAnswers.get(0) != null &&
        selectedAnswers.get(0) === 0
      ) {
        setQuestionSet(QUIZ_QUESTIONS);
        newSelectedAnswers = new Map();
      } else if (question === 0 && selectedAnswers.get(0) == null) {
        if (answer === 0) {
          setQuestionSet(DOG_QUIZ_QUESTIONS);
        } else {
          setQuestionSet(QUIZ_QUESTIONS);
        }
        newSelectedAnswers = new Map();
      }

      newSelectedAnswers.set(question, answer);
      setSelectedAnswers(newSelectedAnswers);
    },
    [setSelectedAnswers, setQuestionSet, selectedAnswers]
  );

  return (
    <div className={styles.buzzFeedQuiz}>
      <div className={styles.body}>
        <div className={styles.metadata}>
          <Badge image="/img/buzzfeed/badge_upward.svg" />
          <Badge image="/img/buzzfeed/badge_quiz.svg" />
          <Badge image="/img/buzzfeed/badge_lol.svg" />
        </div>
        <div className={styles.title}>{QUIZ_NAME}</div>
        <div className={styles.subtitle}>
          You won’t believe which of these polarizing personalities you are...
        </div>
        <div className={styles.byline}>
          <div className={styles.profileImage}>
            <Badge image="/img/buzzfeed/bmoore_small.jpg" />
          </div>
          <div>
            <div className={styles.bylineName}>
              by
              <div className={styles.name}>tots</div>
            </div>
            <div className={styles.bylineTitle}>Scott Family Staff</div>
          </div>
        </div>
        <div className={styles.questionBlock}>
          {questionSet.map((question, index) => (
            <QuestionContainerText
              key={index}
              question={question}
              questionNumber={index}
              selectAnswer={selectAnswer}
              selectedAnswer={selectedAnswers.get(index)}
            />
          ))}
        </div>
        <div className={styles.quizFooter}>
          <div className={styles.buttonGroup}>
            <Button
              intent="primary"
              onClick={completeQuiz}
              text="Submit and view results!"
            />
          </div>
        </div>
        <Dialog
          isOpen={isOpen}
          title={QUIZ_NAME}
          icon={
            <div className={styles.quizBadge}>
              <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                <circle fill="#fe0" cx="25" cy="25" r="25"></circle>
                <path
                  d="M15.782 37.683c.81-.468 1.485-1.065 2.017-1.726l1.32.486.99-2.568-1.114-.417c.295-1.397.06-2.923-.77-4.357-1.826-3.163-5.474-4.01-8.654-2.173-3.18 1.836-4.287 5.427-2.46 8.59 1.827 3.166 5.49 4.002 8.67 2.166zm-1.368-2.37c-1.715.99-3.602.314-4.574-1.37-.98-1.7-.615-3.657 1.1-4.647 1.715-.99 3.578-.32 4.56 1.38.314.546.477 1.116.526 1.67l-1.666-.618-.99 2.568 1.616.604c-.176.164-.37.297-.572.414zm12.905-4.29c3.335-1.927 3.84-4.65 2.347-7.238l-3.564-6.173-2.712 1.566 3.52 6.095c.71 1.23.475 2.552-.96 3.38-1.448.837-2.71.38-3.42-.85l-3.52-6.096-2.728 1.575 3.573 6.188c1.476 2.557 4.128 3.478 7.464 1.552zm9.043-5.472L30.36 15.155l-2.68 1.548L33.68 27.1l2.68-1.55zm9.324-5.382l-1.35-2.338-4.552 2.628 1.014-8.504-1.225-2.12-7.966 4.6 1.35 2.337 4.426-2.555-1.007 8.48 1.232 2.135 8.075-4.662z"
                  fill="#222"
                ></path>
              </svg>
            </div>
          }
          onClose={handleCloseDialog}
          // className={classNames({
          //   [styles.quizResultGradient]: selectedContestant != null,
          // })}
          className={styles.quizResultGradient}
        >
          <DialogBody>
            {selectedContestant == null ? (
              <ResultListBody />
            ) : (
              <QuizResultBody />
            )}
          </DialogBody>
          <DialogFooter
            actions={
              <div>
                <Button
                  text="I refuse to believe this"
                  onClick={handleSetSelfSelectMode}
                />
                <Button
                  intent="primary"
                  text="Close"
                  onClick={handleCloseDialog}
                />
              </div>
            }
          />
        </Dialog>
      </div>
    </div>
  );
});

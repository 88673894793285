import { setWith, TypedReducer } from "redoodle";
import { Instructors, InstructorType, PelotonNavTab } from "../data/PelotonData";
import { PelotonActions } from "./PelotonActions";
import { getInitialPelotonState, PelotonState } from "./PelotonState";

export const pelotonReducer = TypedReducer.builder<PelotonState>()
    .withHandler(
        PelotonActions.selectTab.TYPE,
        (state: PelotonState, selectedTab: PelotonNavTab) => {
            return setWith(state, {
                selectedTab: selectedTab,
                selectedInstuctor: undefined,
                selectedClassType: InstructorType.ALL,
            });
        }
    )
    .withHandler(
        PelotonActions.selectInstructor.TYPE,
        (state: PelotonState, selectedInstuctor: keyof typeof Instructors | undefined) => {
            return setWith(state, {
                selectedInstuctor
            });
        }
    )
    .withHandler(
        PelotonActions.selectClassType.TYPE,
        (state: PelotonState, selectedClassType: InstructorType) => {
            return setWith(state, {
                selectedClassType
            });
        }
    )
    .withDefaultHandler((_state) => getInitialPelotonState())
    .build();

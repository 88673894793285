import { Button, Menu, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { MenuItem2, Popover2 } from "@blueprintjs/popover2";
import * as React from "react";
import { useDispatch } from "react-redux";
import styles from "../../styles/alone/AloneMobileHeaderBar.module.scss";
import { AloneNavTab } from "../data/AloneData";
import { AloneActions } from "../state/AloneActions";

export const AloneMobileHeaderBar = React.memo(
  function AloneMobileHeaderBarInternal() {
    const dispatch = useDispatch();

    const goToAloneHome = React.useCallback(() => {
      dispatch(AloneActions.selectTab(AloneNavTab.HOME));
    }, [dispatch]);

    const goToAloneRules = React.useCallback(() => {
      dispatch(AloneActions.selectTab(AloneNavTab.RULES));
    }, [dispatch]);

    return (
      <div className={styles.mobileHeader}>
        <div className={styles.headerLogo}>
          <img
            className={styles.headerLogoImage}
            src="/img/alone/history_channel_logo.svg"
            alt=""
            onClick={goToAloneHome}
          />
        </div>
        <div className={styles.headerSpacer} />
        <Popover2
          className={styles.aloneMobileHeaderMenuPopover}
          content={
            <Menu className={styles.aloneMobileHeaderNavBarMenu}>
              <MenuItem2
                className={styles.mobileNavBarItem}
                text="Home"
                onClick={goToAloneHome}
              />
              <MenuItem2
                className={styles.mobileNavBarItem}
                text="Rules"
                onClick={goToAloneRules}
              />
            </Menu>
          }
          position={Position.BOTTOM_RIGHT}
          minimal={true}
        >
          <Button icon={IconNames.MENU} minimal={true} />
        </Popover2>
      </div>
    );
  }
);

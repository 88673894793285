import { Classes, Colors, Position } from "@blueprintjs/core/lib/esm/common";
import { MenuItem } from "@blueprintjs/core/lib/esm/components";
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { Menu } from "@blueprintjs/core/lib/esm/components/menu/menu";
import { Popover } from "@blueprintjs/core/lib/esm/components/popover/popover";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import * as React from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { GlobalState } from "../state/globalState";
import scottflixStyles from "../styles/scottflix/Scottflix.module.scss";
import pageStyles from "../styles/shared/Page.module.scss";
import ScottflixBody from "./components/ScottflixBody";
import ScottflixBodyMobile from "./components/ScottflixBodyMobile";
import { ScottflixNavTab } from "./data/ScotflixData";
import { ScottflixActions } from "./redux/ScottflixActions";

export declare namespace Scottflix {
  export interface StateProps {
    selectedTab: ScottflixNavTab;
  }

  export interface ConnectedActions {
    selectTab: (tab: ScottflixNavTab) => void;
  }

  export type Props = StateProps & ConnectedActions;
}

class ScottflixInternal extends React.PureComponent<Scottflix.Props, {}> {
  private renderHeader = () => {
    const { selectTab } = this.props;
    return (
      <div className={scottflixStyles.scottflixPageHeader}>
        <Link className={scottflixStyles.webNavBarImage} to="/scottflix">
          <img
            className={scottflixStyles.scottflixLogoImage}
            src="/img/scottflix/scottflix_logo.png"
            alt=""
            onClick={() => selectTab(ScottflixNavTab.HOME)}
          />
        </Link>
        <div
          className={scottflixStyles.webNavBarText}
          onClick={() => selectTab(ScottflixNavTab.HOME)}
        >
          Home
        </div>
        <div
          className={scottflixStyles.webNavBarText}
          onClick={() => selectTab(ScottflixNavTab.RULES)}
        >
          Rules
        </div>
        <div className={scottflixStyles.horizontalSpacer} />
      </div>
    );
  };

  private renderMobileHeader = () => {
    const { selectTab } = this.props;
    return (
      <div className={scottflixStyles.scottflixPageHeader}>
        <Link className={scottflixStyles.webNavBarImage} to="/scottflix">
          <img
            className={scottflixStyles.scottflixLogoImage}
            src="/img/scottflix/scottflix_logo.png"
            alt=""
            onClick={() => selectTab(ScottflixNavTab.HOME)}
          />
        </Link>
        <div className={scottflixStyles.horizontalSpacer} />
        <Popover
          className={classNames(scottflixStyles.menuPopover, Classes.DARK)}
          content={this.getNavMenu()}
          position={Position.BOTTOM}
          minimal={true}
        >
          <Button icon={IconNames.MENU} minimal={true} color={Colors.WHITE} />
        </Popover>
      </div>
    );
  };

  private getNavMenu() {
    const { selectTab } = this.props;
    return (
      <Menu className={scottflixStyles.mobileNavBarMenu}>
        <MenuItem
          className={scottflixStyles.mobileNavBarItem}
          text="Home"
          onClick={() => selectTab(ScottflixNavTab.HOME)}
        />
        <MenuItem
          className={scottflixStyles.mobileNavBarItem}
          text="Rules"
          onClick={() => selectTab(ScottflixNavTab.RULES)}
        />
      </Menu>
    );
  }

  render() {
    const { selectedTab } = this.props;
    return (
      <div
        className={classNames(pageStyles.page, scottflixStyles.scottflixPage, {
          [scottflixStyles.mobile]: isMobile,
        })}
      >
        {isMobile ? this.renderMobileHeader() : this.renderHeader()}

        <div className={scottflixStyles.scottflixPageBody}>
          {isMobile ? (
            <ScottflixBodyMobile selectedTab={selectedTab} />
          ) : (
            <ScottflixBody selectedTab={selectedTab} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState): Scottflix.StateProps => {
  return {
    selectedTab: state.scottflixState.selectedTab,
  };
};

const mapDispatchToProps = (dispatch: any): Scottflix.ConnectedActions => ({
  selectTab: (tab: ScottflixNavTab) => {
    dispatch(ScottflixActions.selectTab(tab));
  },
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Scottflix = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScottflixInternal);

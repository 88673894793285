import * as React from "react";
import styles from "../../styles/scottify/AlbumCoverMobile.module.scss";

import classNames from "classnames";
import { Playlist } from "../data/ScottifyData";
import { isMobile } from "react-device-detect";

declare namespace AlbumCoverMobile {
  export interface Props {
    playlist: Playlist;
    includeFullTitle?: boolean;
    isSelected: boolean;
    large: boolean;
    onClick: (playlist: Playlist) => void;
  }
}

// eslint-disable-next-line no-redeclare
export default class AlbumCoverMobile extends React.Component<AlbumCoverMobile.Props> {
  render() {
    const { playlist, isSelected, onClick, includeFullTitle, large } = this.props;

    return (
      <div
        className={classNames(styles.albumCoverMobile, { [styles.isSelected]: isSelected, [styles.mobile]: isMobile, [styles.large]: large })}
        onClick={() => onClick(playlist)}
      >
        <div
          className={styles.albumCoverImage}
          style={{
            backgroundImage: `url(/img/scottify/playlist-covers/${
              playlist.image
            })`
          }}
        />
        {includeFullTitle && (<div
          className={classNames(styles.albumCoverTitle, {
            [styles.longTitle]: playlist.title.length > 20,
            [styles.includeFullTitle]: includeFullTitle
          })}
        >
          {playlist.title}
        </div>)}
      </div>
    );
  }
}

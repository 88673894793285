import * as React from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/buzzfeed/QuizResultBody.module.scss";
import { CONTESTANTS } from "../data/BuzzFeedData";
import { getBuzzFeedSelectedContestant } from "../state/BuzzFeedSelectors";

export const QuizResultBody = React.memo(function QuizResultBodyInternal() {
  const selectedContestant = useSelector(getBuzzFeedSelectedContestant);
  const contestant =
    selectedContestant != null
      ? CONTESTANTS.find((c) => c.key === selectedContestant)
      : undefined;

  if (contestant == null) {
    return <div />;
  }

  return (
    <div className={styles.quizResultBody}>
      <div className={styles.title}>You are ... {contestant.name}!</div>
      <div className={styles.result}>
        <div className={styles.section}>
          <div className={styles.name}>{contestant.name}</div>
        </div>
        <div className={styles.section}>
          <div className={styles.bio}>{contestant.bio}</div>
        </div>
        <div className={styles.primaryPicSection}>
          <div
            className={styles.pic}
            style={{ backgroundImage: "url(" + contestant.primaryImgUrl + ")" }}
          />
        </div>
      </div>
    </div>
  );
});

export interface SecretSantaSite {
  title: string;
  relativeUrl: string;
  year: string;
  backgroundImg: string;
}

export const SECRET_SANTA_SITES: SecretSantaSite[] = [
  {
    title: "BuzzFeed",
    relativeUrl: "/buzzfeed",
    year: "2023",
    backgroundImg: "img/buzzfeed/buzzfeed_logo.png", // todo: change
  },
  {
    title: "Alone",
    relativeUrl: "/alone",
    year: "2022",
    backgroundImg: "img/alone/alone.png",
  },
  {
    title: "Peloton",
    relativeUrl: "/peloton",
    year: "2021",
    backgroundImg: "img/peloton/PelotonLogoHome.png",
  },
  {
    title: "Scottify",
    relativeUrl: "/scottify",
    year: "2020",
    backgroundImg: "img/scottify/ScottifyLogo.png",
  },
  {
    title: "Scottflix",
    relativeUrl: "/scottflix",
    year: "2019",
    backgroundImg: "img/scottflix/scottflix_logo.png",
  },
  {
    title: "Top Secret Santa 2018",
    relativeUrl: "/top-secret-eighteen",
    year: "2018",
    backgroundImg: "img/top-secret-santa-18/TopSecretSanta2018.png",
  },
  {
    title: "Top Secret Santa 2017",
    relativeUrl: "/top-secret-seventeen",
    year: "2017",
    backgroundImg: "img/top-secret-santa-17/TopSecretSanta2017.png",
  },
];
